<template>
  <div class="">
    <el-row>
      <el-col :span="5">
        <el-card class="box-card" :body-style="{ padding: '0px' }">
          <div slot="header" class="jrjiuzhen">
            <el-badge
              :value="$store.state.notice.visitlist.length"
              type="warning"
            >
              <el-tag>今日就诊</el-tag>
            </el-badge>
            <div style="display: flex;">
              <el-date-picker
                v-model="date"
                format="MM-dd"
                :clearable="false"
                :editable="false"
                type="date"
                size="small"
                @change="change(date)"
              >
              </el-date-picker>
              <el-button style="margin-left: 5px;" type="success" v-debounce size="mini" icon="el-icon-plus" @click="opennewmeetvisit">挂 号</el-button>
            </div>
          </div>
          <div class="sketch_content" style="height: 700px">
            <div class="searchpatient">
              <el-input
                clearable
                placeholder="搜索患者"
                prefix-icon="el-icon-search"
                @input="searchphoneororderInput"
                @blur="searchphoneororderblur"
                v-model="searchpatientvalue">
              </el-input>
            </div>
            <div class="flexcolumn" v-show="showsearchpatientvalue">
              <div class="searchpatienttotal">搜索到<span class="jiachu"> {{ searchorderlist.length }} </span>条信息</div>
              <div class="searchpatientitem" 
                v-for="(patient,index) in searchorderlist" 
                :key="patient._id"
                :class="chooceactive == patient._id ? 'chooceactive' : ''"
                @click="chooceclass(index, patient)"
              >
                <div class="flexrow">
                  <div><i :class="patient.gender === 1 ? 'el-icon-male' : 'el-icon-female'"></i></div>
                  <div :class="patient.markings === true ? 'weizhen' : ''">{{ patient.meetperson }}</div>
                </div>
                <div class="flexrow">
                  <div class="mr20">{{ patient.start }}</div>
                  <div v-if="patient.statusvisit === 'finish'" class="yizhen">已诊</div>
                  <div v-if="patient.statusvisit === 'visiting'" class="jiuzhenzhong">诊中</div>
                  <div v-if="patient.statusvisit === 'waiting'" class="weizhen">待诊</div>
                  <div v-if="patient.statusvisit === 'cancelled'" class="weizhen">已取消</div>
                </div>
              </div>
            </div>
            <div
              v-show="!showsearchpatientvalue"
              v-for="(items, index) in $store.state.notice.visitlist"
              :key="items._id"
              class="meetlist"
              :class="chooceactive == items._id ? 'chooceactive' : ''"
              @click="chooceclass(index, items)"
            >
              <div class="flexrow">
                <div><i :class="items.gender === 1 ? 'el-icon-male' : 'el-icon-female'"></i></div>
                <div :class="items.markings === true ? 'weizhen' : ''">{{ items.meetperson }}</div>
              </div>
              <div class="flexrow">
                <div class="mr20">{{ items.products.firsttime }}-{{ items.products.endtime }}</div>
                <div v-if="items.statusvisit === 'finish'" class="yizhen">已诊</div>
                <div v-if="items.statusvisit === 'visiting'" class="jiuzhenzhong">诊中</div>
                <div v-if="items.statusvisit === 'waiting'" class="weizhen">待诊</div>
                <div v-if="items.statusvisit === 'cancelled'" class="weizhen">已取消</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <!--中间-->
      <!--个人信息-->
      <el-col :span="13" style="padding-left: 10px">
        <el-card class="userinfo" :body-style="{ padding: '0px' }">
          <div class="userinfobox">
            <div class="h3title">个人信息</div>
            <div>
              <el-button v-debounce v-if="meetinfo.statusvisit === 'waiting'" type="primary" size="mini" @click="confirmvisit(meetinfo)">接 诊</el-button>
              <el-button v-debounce v-if="meetinfo.statusvisit === 'visiting'" type="success" size="mini" @click="saveprescription">完 诊</el-button>
              <el-button v-debounce v-if="meetinfo.statusvisit === 'visiting'" type="warning" size="mini" @click="recallnum">叫号播报</el-button>
              <el-button v-if="meetinfo.statusvisit === 'cancelled'" type="primary" size="mini" @click="confirmvisit(meetinfo)">重新接诊</el-button>
              <el-button v-debounce v-if="meetinfo.statusvisit === 'visiting'" type="info" size="mini" @click="openchangedoctorDialog">转 诊</el-button>
              <el-button v-debounce v-if="meetinfo.statusvisit === 'visiting'" type="danger" size="mini" @click="cancellvisit(meetinfo._id)">过 号</el-button>
              <el-button plain v-if="meetinfo.statusvisit === 'finish' && meetinfo.blswitch" type="primary" size="mini" @click="editmedicalrecordswitch(meetinfo)">修 改</el-button>
              <el-button v-if="meetinfo.statusvisit === 'finish' && !meetinfo.blswitch" type="primary" size="mini" @click="editsavemedicalrecordswitch">保 存</el-button>
              <el-button v-if="meetinfo.statusvisit === 'finish' && !meetinfo.blswitch" type="warning" size="mini" @click="cancelmedicalrecordswitch(meetinfo)">取 消</el-button>
            </div>
          </div>
          <div class="userinfoboxwrapper">
            <div class="input-row">
              <el-input
               :disabled="meetinfo.blswitch"
                placeholder="姓名"
                type="text"
                v-model="meetinfo.meetperson"
                @input="agreeChange(meetinfo._id, meetinfo.meetperson, 'meetperson');"
                style="border: none; outline: none; background: transparent; width: 100%; box-sizing: border-box;" 
                prefix-icon="el-icon-user">
              </el-input>
              <el-input
                :disabled="meetinfo.blswitch"
                type="text"
                v-model="meetinfo.mobile"
                @input="agreeChange(meetinfo._id, meetinfo.mobile, 'mobile');"
                placeholder="手机"
                prefix-icon="el-icon-mobile-phone">
              </el-input>
              <el-input
                :disabled="meetinfo.blswitch"
                type="text"
                v-model="meetinfo.age"
                @input="agreeChange(meetinfo._id, meetinfo.age, 'age');"
                placeholder="年龄"
                prefix-icon="el-icon-bangzhu">
              </el-input>
              <el-select 
                  :disabled="meetinfo.blswitch"
                  @change="(val)=>{agreeChange(meetinfo._id,val,'gender')}"
                  v-model="meetinfo.gender" 
                  placeholder="性别">
                  <el-option
                    v-for="item in sexoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
              </el-select>
              <div>
              </div>
            </div>
          </div>
        </el-card>
        <!--个人信息-->
        <!--病历-->
        <el-card class="userinfo" :body-style="{ padding: '0px' }" style="margin-top: 5px;">
          <div class="userinfobox">
            <div class="h3title">病历</div>
          </div>
          <div class="binglibox">
            <!--主诉-->
            <div class="bingliitem" >
              <div class="binglititle">主诉</div>
              <div style="flex-grow: 1;">
                <el-popover
                  popper-class="mylePopover"
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover1"
                  v-model="showPopuppatientdescribe"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.chiefcomplaint" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addpatientdescribe(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopuppatientdescribe')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopuppatientdescribe')"
                    autosize
                    type="textarea"
                    ref="showPopuppatientdescribe"
                    @blur="focusInput('showPopuppatientdescribe')"
                    v-model="meetinfo.medicalrecord.patientdescribe"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.patientdescribe, 'medicalrecord.patientdescribe');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--主诉-->
            <!--现病史-->
            <div class="bingliitem" >
              <div class="binglititle">现病史</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover2"
                  v-model="showPopuphistoryofpresent"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.historyofpresentillness" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addhistoryofpresent(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopuphistoryofpresent')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopuphistoryofpresent')"
                    autosize
                    type="textarea"
                    ref="showPopuphistoryofpresent"
                    @blur="focusInput('showPopuphistoryofpresent')"
                    v-model="meetinfo.medicalrecord.historyofpresent"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.historyofpresent, 'medicalrecord.historyofpresent');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--现病史-->
            <!--既往史-->
            <div class="bingliitem" >
              <div class="binglititle">既往史</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover3"
                  v-model="showPopuppasthistory"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.pasthistory" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addpasthistory(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopuppasthistory')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopuppasthistory')"
                    autosize
                    type="textarea"
                    ref="showPopuppasthistory"
                    @blur="focusInput('showPopuppasthistory')"
                    v-model="meetinfo.medicalrecord.pasthistory"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.pasthistory, 'medicalrecord.pasthistory');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--既往史-->
            <!--过敏史-->
            <div class="bingliitem" >
              <div class="binglititle">过敏史</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover4"
                  v-model="showPopupallergyhistory"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.allergyhistory" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addallergyhistory(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopupallergyhistory')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopupallergyhistory')"
                    autosize
                    type="textarea"
                    ref="showPopupallergyhistory"
                    @blur="focusInput('showPopupallergyhistory')"
                    v-model="meetinfo.medicalrecord.allergyhistory"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.allergyhistory, 'medicalrecord.allergyhistory');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--过敏史-->
            <!--检查-->
            <div class="bingliitem" >
              <div class="binglititle">检查</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover5"
                  v-model="showPopupinspect"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.inspect" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addinspect(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopupinspect')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopupinspect')"
                    autosize
                    type="textarea"
                    ref="showPopupinspect"
                    @blur="focusInput('showPopupinspect')"
                    v-model="meetinfo.medicalrecord.inspect"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.inspect, 'medicalrecord.inspect');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--检查-->
            <!--望闻切诊-->
            <div class="bingliitem" >
              <div class="binglititle">望闻切诊</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover6"
                  v-model="showPopupauscultationandpalpation"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.palpationanddiagnosis" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addauscultationandpalpation(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopupauscultationandpalpation')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopupauscultationandpalpation')"
                    autosize
                    type="textarea"
                    ref="showPopupauscultationandpalpation"
                    @blur="focusInput('showPopupauscultationandpalpation')"
                    v-model="meetinfo.medicalrecord.auscultationandpalpation"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.auscultationandpalpation, 'medicalrecord.auscultationandpalpation');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--望闻切诊-->
            <!--诊断-->
            <div class="bingliitem" >
              <div class="binglititle">诊断</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover7"
                  v-model="showPopupdiagnosis"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.diagnosis" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="adddiagnosis(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopupdiagnosis')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopupdiagnosis')"
                    autosize
                    type="textarea"
                    ref="showPopupdiagnosis"
                    @blur="focusInput('showPopupdiagnosis')"
                    v-model="meetinfo.medicalrecord.diagnosis"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.diagnosis, 'medicalrecord.diagnosis');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--诊断-->
            <!--辨证-->
            <div class="bingliitem" >
              <div class="binglititle">辨证</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover8"
                  v-model="showPopupdialectical"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.discriminate" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="adddialectical(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopupdialectical')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopupdialectical')"
                    autosize
                    type="textarea"
                    ref="showPopupdialectical"
                    @blur="focusInput('showPopupdialectical')"
                    v-model="meetinfo.medicalrecord.dialectical"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.dialectical, 'medicalrecord.dialectical');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--辨证-->
            <!--医嘱-->
            <div class="bingliitem" >
              <div class="binglititle">医嘱</div>
              <div style="flex-grow: 1;">
                <el-popover
                  placement="top"
                  width="700"
                  trigger="manual"
                  ref="filterPopover9"
                  v-model="showPopupexhort"
                  >
                  <div @mousedown.prevent>
                    <el-tabs type="border-card" class="digolPopover">
                      <el-tab-pane label="快捷输入">
                        <div class="shortcuttitle" v-for="item in categorizedData.exhort" :key="item.title">
                          <h5>{{ item.title }}</h5>
                          <div class="shortcut" v-for="contentItem in item.content.split('，')" @click="addexhort(contentItem.trim())" :key="contentItem.trim()">{{ contentItem.trim() }}</div>
                        </div>
                        <div style="text-align: right; margin-top: 30px">
                          <el-button type="warning" size="mini" @click.prevent="guanbi('showPopupexhort')">关闭</el-button>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                  <el-input
                    :disabled="meetinfo.blswitch"
                    placeholder="请输入内容"
                    slot="reference"
                    resize="none"
                    @focus="handleInputFocus('showPopupexhort')"
                    autosize
                    type="textarea"
                    ref="showPopupexhort"
                    @blur="focusInput('showPopupexhort')"
                    v-model="meetinfo.medicalrecord.exhort"
                    @input="agreeChange(meetinfo._id, meetinfo.medicalrecord.exhort, 'medicalrecord.exhort');"
                  ></el-input>
                </el-popover>
              </div>  
            </div>
            <!--医嘱-->
          </div>
        </el-card>
        <!--病历-->
        <!--治疗项目-->
        <el-card class="userinfo" :body-style="{ padding: '0px' }" style="margin-top: 5px;overflow: visible;">
          <div class="userinfobox borderbottom">
            <div class="h3title">治疗项目</div>
          </div>
          <div class="userinfoboxwrapper">
            <ul class="goods-item-list">
              <li @mouseenter="showzhengliaoxiangmu = index" @mouseleave="showzhengliaoxiangmu = null" class="goods-item" v-for="(item,index) in meetinfo.medicalrecord.consulting" :key="index">
                <div class="index">{{index + 1}}</div>
                <div class="name">
                  <div class="name-describe">
                    <div class="">
                      <span class="ellipsis ellipsis-goods-name">{{ item.fullName }}</span>
                      <span class="spec">{{ item.displayspec }}</span>
                      <span class="shortage-tips" v-if="item.type !== 4 && item.type !== 5 && item.selectedPackageUnit === item.packageUnit && item.defaultcount > item.inventory">
                        <el-tooltip class="item" effect="dark" content="库存不足" placement="top">
                          <i class="el-icon-warning" style="color: #ff8100;"></i>
                        </el-tooltip>
                      </span>
                      <span class="shortage-tips" v-if="item.type !== 4 && item.type !== 5 && item.selectedPackageUnit === item.pieceUnit && item.defaultcount > item.split + item.inventory*item.pieceNum">
                        <el-tooltip class="item" effect="dark" content="库存不足" placement="top">
                          <i class="el-icon-warning" style="color: #ff8100;"></i>
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="tag-wrap"></div>
                  </div>
                </div>
                <div class="goods-type">{{ item.type === 4 ? '治疗' : item.type === 5 ? '理疗' : item.type === 3 ? '商品' : item.type === 6 ? '物资' : '其他' }}</div>
                <div class="dosage">
                  <div class="bwhl-form-item" v-if="item.type === 4 || item.type === 5">
                    <div class="bwhl-form-item-content">
                      <div class="bwhl-input-wrapper count-center underline-input">
                        <input :disabled="meetinfo.blswitch || item.ischarge" @click="handleInputClick" v-model="item.defaultfrequency" @input="updateDefaultCount(item)" type="number" autocomplete="off" spellcheck="false" maxlength="500" placeholder="每天" class="bwhl-input__inner" style="width: 47px;">
                      </div>
                    </div>
                  </div>
                  <div class="bwhl-form-item" v-if="item.type === 4 || item.type === 5">
                    <div class="bwhl-form-item-content">
                      <span :title="item.packageUnit" class="unit">{{ item.packageUnit }}</span>
                    </div>
                  </div>
                </div>
                <div class="days">
                  <div class="bwhl-form-item" v-if="item.type === 4 || item.type === 5">
                    <div class="bwhl-form-item-content">
                      <div class="bwhl-input-wrapper count-center underline-input">
                        <input :disabled="meetinfo.blswitch || item.ischarge" @click="handleInputClick" v-model="item.defaultdays" @input="updateDefaultCount(item)" type="number" autocomplete="off" spellcheck="false" maxlength="500" class="bwhl-input__inner" style="width: 58px; margin: 0px; padding: 3px 24px 3px 3px;">
                      </div>
                      <span class="input-append-unit">天</span>
                    </div>
                  </div>
                </div>
                <div class="unit-count">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div class="bwhl-input-wrapper count-center underline-input">
                        <input :disabled="meetinfo.blswitch || item.ischarge" type="number" @click="handleInputClick" v-model="item.defaultcount"  autocomplete="off" spellcheck="false" maxlength="500" placeholder="总量" data-cy="bwhl-input-inner-总量" class="bwhl-input__inner" style="width: 47px;"> 
                      </div>
                    </div>
                  </div>
                <div class="bwhl-form-item">
                  <div class="bwhl-form-item-content">
                    <select :disabled="meetinfo.blswitch || item.ischarge" v-model="item.selectedPackageUnit" class="package-unit-select unit">
                      <option v-for="unit in getPackageUnits(item)" :key="unit" :value="unit">{{ unit }}</option>
                    </select>
                  </div>
                </div>
                </div>
                <div class="total">¥ {{ totalPrices[index] }}</div> 
                <div title="" class="item-remark">
                  <div data-cy="item-remark" class="bwhl-input-wrapper no-border-input underline-input">
                    <input :disabled="meetinfo.blswitch" type="text" v-model="item.notes" autocomplete="off" spellcheck="false" maxlength="500" placeholder="备注" data-cy="bwhl-input-inner-备注" class="bwhl-input__inner" style="width: 100%;">
                  </div>
                </div>
                <div class="delete-item" v-if="showzhengliaoxiangmu === index && !meetinfo.blswitch && !item.ischarge" @click="deleteconsultingItem(index)">
                  <div data-v-a0c32478="" class="delete-icon -delete-icon dark-delete-icon" data-cy="item-delete">
                    <i class="el-icon-error" style="color: #ff8100; font-size: 20px; line-height: 20px;"></i>
                  </div>
                </div>
              </li>
            </ul>
            <div class="zhengliaoxiangmu" >
                <div style="flex-grow: 1;">
                  <el-popover
                    trigger="manual"
                    v-model="showPopupzhengliaoxiangmu"
                    placement="bottom"
                    width="100%">
                    <el-table
                      @row-click="handleRowClickconsulting"
                      class="my-div" 
                      :data="gridDataceshi1"
                      height="100%"
                      style="min-height: 300px;max-height: 400px; overflow-y: auto;"
                      :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                    >
                      <el-table-column type="index" width="50"></el-table-column>
                      <el-table-column width="260" align="left" property="fullName" label="项目名/商品名"></el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="100" align="center"  property="displayspec" label="单位/规格"></el-table-column>
                      <el-table-column width="120" align="center" label="库存" style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <template slot-scope="scope" v-if="scope.row.type !== 4 && scope.row.type !== 5">
                          <span v-if="scope.row.dismounting === 1">{{ scope.row.inventory + scope.row.packageUnit +'/'+ scope.row.split + scope.row.pieceUnit }}</span>
                          <span v-if="scope.row.dismounting === 0">{{ scope.row.inventory + scope.row.packageUnit }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="80" align="center" label="价格">
                        <template slot-scope="scope">
                          {{ '¥' +scope.row.sellprice }}
                        </template>
                      </el-table-column>
                      <el-table-column align="center" width="80" label="类别">
                        <template slot-scope="scope">
                          {{ scope.row.type === 3 ? '商品' : scope.row.type === 4 ? '治疗' : scope.row.type === 5 ? '理疗' : scope.row.type === 6 ? '物资' : '其他' }}
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="80" property="manufacturer" label="厂家"></el-table-column>
                    </el-table>
                    <el-input 
                      :disabled="meetinfo.blswitch"
                      slot="reference"
                      @input="searchtreatmentandmedicineInput"
                      @focus="searchtreatmentandmedicineFocus"
                      @blur="searchtreatmentandmedicineblur" 
                      placeholder="输入项目名或拼音首字母"
                      v-model="searchchconsulting"
                      style="border: none; outline: none; background: transparent; width: 100%; box-sizing: border-box;" 
                      prefix-icon="el-icon-circle-plus-outline">
                    </el-input>
                  </el-popover>
                </div>
                <div style="margin:0 16px;">¥ {{ totalCost }}</div>
              </div>
          </div>
        </el-card>
        <!--治疗项目-->
        <!--中西成药-->
        <el-card class="userinfo" :body-style="{ padding: '0px' }" style="margin-top: 5px;overflow: visible;">
          <div class="userinfobox borderbottom">
            <div class="h3title">中西成药</div>
            <div> 
              <el-button :disabled="meetinfo.blswitch" type="primary" size="mini" @click="addzhcnrecipeArray">中药处方</el-button>
            </div>
          </div>
          <div class="userinfoboxwrapper">
            <ul class="goods-item-list">
              <li @mouseenter="showDiv = index" @mouseleave="showDiv = null" class="goods-item zxcy" v-for="(item,index) in meetinfo.medicalrecord.westrecipe" :key="index">
                <div class="index">{{index + 1}}</div>
                <div class="name">
                  <div class="name-describe">
                    <div class="">
                      <span class="ellipsis ellipsis-goods-name">{{ item.fullName }}</span>
                      <span data-v-a0c32478="" class="spec">{{ item.displayspec }}</span>
                      <span class="shortage-tips" v-if="item.type === 1 && item.selectedPackageUnit === item.packageUnit && item.defaultcount > item.inventory">
                        <el-tooltip class="item" effect="dark" content="库存不足" placement="top">
                          <i class="el-icon-warning" style="color: #ff8100;"></i>
                        </el-tooltip>
                      </span>
                      <span class="shortage-tips" v-if="item.type === 1 && item.selectedPackageUnit === item.pieceUnit && item.defaultcount > item.inventory*item.pieceNum + item.split">
                        <el-tooltip class="item" effect="dark" content="库存不足" placement="top">
                          <i class="el-icon-warning" style="color: #ff8100;"></i>
                        </el-tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="usage" style="text-align: center;">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                        <div class="bwhl-input-wrapper count-center">
                          <el-popover
                            placement="bottom"
                            v-model="showPopupusageselect[index]"
                            trigger="manual">
                            <div class="selectcontainer">
                              <div class="column" @click="handleUsageClick(item,itemusage)" v-for="(itemusage,index) in usageselect.internaluse" :key="index">{{ itemusage }}</div>
                            </div>
                            <div class="selectcontainer mt10">
                              <div class="column" @click="handleUsageClick(item,itemusage)" v-for="(itemusage,index) in usageselect.injection" :key="index">{{ itemusage }}</div>
                            </div>
                            <div class="selectcontainer mt10">
                              <div class="column" @click="handleUsageClick(item,itemusage)" v-for="(itemusage,index) in usageselect.externaluse" :key="index">{{ itemusage }}</div>
                            </div>
                            <input 
                              slot="reference"
                              :disabled="meetinfo.blswitch || item.ischarge" 
                              @focus="handleInputFocususage(index)"
                              @blur="handleInputblurusage(index)" 
                              v-model="item.usage" 
                              type="text" 
                              autocomplete="off" 
                              spellcheck="false" 
                              maxlength="500" 
                              placeholder="用法" 
                              class="bwhl-input__inner">
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="dosage" style="text-align: center;">
                  <div class="bwhl-form-item" style="flex-grow: 1;margin-right: 0;">
                    <div class="bwhl-form-item-content">
                      <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                        <div class="bwhl-input-wrapper count-center">
                          <el-dropdown :disabled="meetinfo.blswitch || item.ischarge" class="custom-dropdown" trigger="click"  @command="handlechangefreqs">
                            <span class="el-dropdown-link">
                              {{ item.freq ? item.freq : "频率" }}
                            </span>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item v-for="(freqs,index) in freqselect" :key="index" :command="changefreqs(item,freqs.frequency,freqs.multiplier)">{{ freqs.frequency }}</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="unit-count-dosage">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div class="bwhl-input-wrapper count-center">
                        <input :disabled="meetinfo.blswitch || item.ischarge" type="text" @input="updatewestrecipeCount(item)" @click="handleInputClick" v-model="item.dosage" autocomplete="off" spellcheck="false" maxlength="500" placeholder="用量" class="bwhl-input__inner" style="width: 47px;"> 
                      </div>
                    </div>
                  </div>
                  <div class="bwhl-form-item ishover">
                    <div class="bwhl-form-item-content">
                      <select :disabled="meetinfo.blswitch || item.ischarge" v-model="item.selecteddosageUnit" @change="updatewestrecipeCount(item)" class="package-unit-select unit" style="text-align: center;">
                        <option v-for="unit in selectdosageunit(item)" :key="unit" :value="unit">{{ unit }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="days">
                  <div class="bwhl-form-item">
                    <div class="bwhl-form-item-content">
                      <div class="bwhl-input-wrapper count-center">
                        <input :disabled="meetinfo.blswitch || item.ischarge" v-model="item.defaultdays" @input="updatewestrecipeCount(item)" type="number" autocomplete="off" spellcheck="false" maxlength="500" class="bwhl-input__inner" style="width: 58px; margin: 0px; padding: 3px 24px 3px 3px;">
                      </div>
                      <span class="input-append-unit">天</span>
                    </div>
                  </div>
                </div>
                <div class="unit-count-dosage">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div class="bwhl-input-wrapper count-center">
                        <input :disabled="meetinfo.blswitch || item.ischarge" type="text" v-model="item.defaultcount" autocomplete="off" spellcheck="false" maxlength="500" placeholder="总量" data-cy="bwhl-input-inner-总量" class="bwhl-input__inner" style="width: 47px;"> 
                      </div>
                    </div>
                  </div>
                  <div class="bwhl-form-item ishover">
                    <div class="bwhl-form-item-content">
                      <select :disabled="meetinfo.blswitch || item.ischarge" v-model="item.selectedPackageUnit" class="package-unit-select unit" style="text-align: center;" @change="handlezxcySelectChange(item)">
                        <option v-for="unit in getPackageUnits(item)" :key="unit" :value="unit">{{ unit }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="total">¥{{ westrecipetotalPrices[index] }}</div> 
                <div title="" class="item-remark">
                  <div data-cy="item-remark" class="bwhl-input-wrapper no-border-input">
                    <input :disabled="meetinfo.blswitch || item.ischarge" type="text" v-model="item.notes" autocomplete="off" spellcheck="false" maxlength="500" placeholder="备注" class="bwhl-input__inner" style="width: 100%;">
                  </div>
                </div>
                <div class="delete-item" v-if="showDiv === index && !meetinfo.blswitch && !item.ischarge" @click="deletewestrecipeItem(index)">
                  <div data-v-a0c32478="" class="delete-icon -delete-icon dark-delete-icon" data-cy="item-delete">
                    <i class="el-icon-error" style="color: #ff8100; font-size: 20px; line-height: 20px;"></i>
                  </div>
                </div>
              </li>
            </ul>
            <div class="zhengliaoxiangmu" >
                <div style="flex-grow: 1;">
                  <el-popover
                    trigger="manual"
                    v-model="showPopupzhongxichengyao"
                    placement="bottom"
                    width="100%">
                    <el-table
                      @row-click="handleRowClickwestrecipe"
                      class="my-div" 
                      :data="gridDataceshi2"
                      height="100%"
                      style="min-height: 300px;max-height: 400px; overflow-y: auto;"
                      :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                    >
                      <el-table-column width="300" align="left" property="fullName" label="项目名/商品名"></el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="120" align="center"  property="displayspec" label="单位/规格"></el-table-column>
                      <el-table-column width="140" align="center" label="库存" style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <template slot-scope="scope" v-if="scope.row.type === 1">
                          <span v-if="scope.row.dismounting === 1">{{ scope.row.inventory + scope.row.packageUnit +'/'+ scope.row.split + scope.row.pieceUnit }}</span>
                          <span v-if="scope.row.dismounting === 0">{{ scope.row.inventory + scope.row.packageUnit }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column width="120" align="center" label="价格">
                        <template slot-scope="scope">
                          {{ '¥' + scope.row.sellprice }}
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="80" property="manufacturer" label="厂家"></el-table-column>
                    </el-table> 
                    <el-input
                      :disabled="meetinfo.blswitch"
                      slot="reference"
                      @input="searchwesternmedicineInput"
                      @focus="searchwesternmedicineFocus"
                      @blur="searchwesternmedicineblur"
                      placeholder="输入项目名或拼音首字母"
                      type="text"
                      v-model="searchwesternmedicine"
                      style="border: none; outline: none; background: transparent; width: 100%; box-sizing: border-box;" 
                      prefix-icon="el-icon-circle-plus-outline">
                    </el-input>
                  </el-popover>
                </div>
                <div style="margin:0 16px;">¥ {{westrecipetotalCost }}</div>
              </div>
          </div>
        </el-card>
        <!--中西成药-->
        <!--中药处方-->
        <el-card class="userinfo" v-for="(item,index) in meetinfo.medicalrecord.zhcnrecipe" :key="index" :body-style="{ padding: '0px' }" style="margin-top: 5px;overflow: visible;">
          <div class="userinfobox borderbottom">
            <div class="h3title">中药处方{{ numberToWords(index + 1) }}</div>
            <div>
              <el-button type="primary" size="mini" :disabled="meetinfo.blswitch || item.ischarge" @click="openmedicineTemplate(index)">处方模版</el-button>
              <el-button size="mini" :disabled="meetinfo.blswitch || item.ischarge" type="danger" icon="el-icon-delete" circle @click="delzhcnrecipeArray(index)"></el-button>
            </div>
          </div>
          <div class="bottomboxborder zycf">
            <div class="child" @mouseenter="handleMouseEnter(index,keyid)" @mouseleave="handleMouseLeave(index,keyid)" v-for="(Zynameitem,keyid) in item.productInfos" :key="keyid">
              <div class="name-wrapper">
                <div class="flexrow aligncenter zycf-autocomplete-wrapper medicine-autocomplete zycf-input-small-wrapper">
                  <div :id="`Zynameitem${index}${keyid}`" class="transition-element" :data-name="Zynameitem.fullName" @click="displayNamefocusInput(index, keyid,Zynameitem.fullName)">{{ Zynameitem.fullName }}</div>
                  <div :id="`displayName${index}${keyid}`" class="Zynameitemindex">
                    <el-popover
                      :ref="`zhcnrecipepopover_${index}_${keyid}`"
                      placement="top-start"
                      trigger="manual"
                      width="100%">
                    <el-table
                      @row-click="clickupupdatazhcnrecipe"
                      class="my-div"
                      :data-index=index
                      :data-keyid=keyid
                      :data="gridDataceshi3"
                      height="100%"
                      style="min-height: 300px;max-height: 400px; overflow-y: auto;"
                      :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                    >
                      <el-table-column width="360" align="left" property="fullName" label="项目名/商品名"></el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="100" align="center"  property="displayspec" label="单位/规格"></el-table-column>
                      <el-table-column width="80" align="center" label="库存" style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <template slot-scope="scope">
                          {{ scope.row.inventory + scope.row.packageUnit}}
                        </template>
                      </el-table-column>
                      <el-table-column width="80" align="center" label="价格">
                        <template slot-scope="scope">
                          {{ '¥' +scope.row.sellprice }}
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="80" property="manufacturer" label="厂家"></el-table-column>
                    </el-table>
                    <el-input
                      :disabled="meetinfo.blswitch || item.ischarge"
                      class="zycf-input__inner"
                      slot="reference"
                      placeholder="药品"
                      type="text"
                      @input="searchdisplayNameInput"
                      @focus="updatazhcnrecipeInputFocus(index,keyid)"
                      @blur="updatazhcnrecipeblurInput(index,keyid)"
                      v-model="serachdisplayName"
                      :id="`inputFocus_${index}_${keyid}`"
                      style="border: none; outline: none; background: transparent; width: 100%; box-sizing: border-box;">
                    >
                    </el-input>
                    </el-popover>
                  </div>
                  <span class="shortage-tips lineheight50" v-if="Zynameitem.grammage > Zynameitem.inventory">
                    <el-tooltip class="item" effect="dark" content="库存不足" placement="top">
                      <i class="el-icon-warning" style="color: #ff8100;"></i>
                    </el-tooltip>
                  </span>
                </div>
              </div>
              <div class="count-wrapper">
                <div class="zycf-form-item">
                  <div class="zycf-form-item-content">
                    <div data-cy="input-count" class="zycf-input-wrapper count zycf-input-small-wrapper">
                      <input :disabled="meetinfo.blswitch || item.ischarge" type="text" @input="restrictToPositiveInteger(item,keyid,Zynameitem.grammage)" :id="grammagefocusRefs(index,keyid)" v-model="Zynameitem.grammage" autocomplete="off" spellcheck="false" maxlength="6" placeholder="用量" class="zycf-input__inner" style="border: none; outline: none;text-align: right;width: 100%; margin: 0px -1px 0px 0px; padding: 3px 16px 3px 0px;">
                    </div>
                    <span class="input-append-unit">g</span>
                  </div>
                </div>
              </div>
              <div class="delete-icon-wrapper" @click="deletezhcnrecipes(index,keyid)" v-if="showDeleteIcon[index] && showDeleteIcon1[keyid] && !meetinfo.blswitch && !item.ischarge">
                <div class="delete-icon">
                  <i class="el-icon-error" style="font-size: 20px; line-height: 20px;"></i>
                </div>
              </div>
            </div>
            <div class="child">
              <div class="name-wrapper">
                <div class="zycf-autocomplete-wrapper medicine-autocomplete zycf-input-small-wrapper">
                  <el-popover
                    trigger="manual"
                    v-model="showPopupzhcnrecipe[index]"
                    placement="bottom"
                    width="100%">
                    <el-table
                      @row-click="handleRowClickzhcnrecipe"
                      class="my-div" 
                      :data="gridDataceshi3"
                      height="100%"
                      style="min-height: 300px;max-height: 400px; overflow-y: auto;"
                      :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                    >
                      <el-table-column width="360" align="center"  property="fullName" label="项目名/商品名"></el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="100" align="center"  property="displayspec" label="单位/规格"></el-table-column>
                      <el-table-column width="80" align="center" label="库存" style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                        <template slot-scope="scope">
                          {{ scope.row.inventory + scope.row.packageUnit}}
                        </template>
                      </el-table-column>
                      <el-table-column width="80" align="center" label="价格">
                        <template slot-scope="scope">
                          {{ '¥' +scope.row.sellprice }}
                        </template>
                      </el-table-column>
                      <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="80" property="manufacturer" label="厂家"></el-table-column>
                    </el-table>
                    <el-input
                      :disabled="meetinfo.blswitch || item.ischarge"
                      class="zycf-input__inner"
                      slot="reference"
                      @input="searchchinesemedicineInput"
                      @focus="zhcnrecipeInputFocus(index)"
                      @blur="zhcnrecipefocusInput(index)"
                      placeholder="药品"
                      type="text"
                      v-model="searchZynameitem"
                      style="border: none; outline: none; background: transparent; width: 100%; box-sizing: border-box;">
                    </el-input>
                  </el-popover>
                </div>
              </div>
              <div class="count-wrapper">
                <div class="zycf-form-item">
                  <div class="zycf-form-item-content">
                    <div data-cy="input-count" class="zycf-input-wrapper count zycf-input-small-wrapper">
                      <input :disabled="meetinfo.blswitch || item.ischarge" type="text" autocomplete="off" spellcheck="false" maxlength="6" placeholder="用量" class="zycf-input__inner" style="border: none; outline: none;text-align: right;width: 100%; margin: 0px -1px 0px 0px; padding: 3px 16px 3px 0px;">
                    </div>
                    <span class="input-append-unit">g</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottombox flexrow">
            <div class="flex-item—1">
              <div class="nested-flex">
                <div class="zycfdose flexrow">
                  <input type="text" v-model="item.dosecount" class="input-element">
                  <div class="child-div">剂</div>
                </div>
                <div class="zycfusage" style="text-align: center;">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                        <div class="bwhl-input-wrapper count-center">
                          <el-popover
                            placement="bottom"
                            :value="showPopupzycfusageselect[index]"
                            trigger="manual">
                            <div class="selectcontainer">
                              <div class="column" @click="handlezycfUsageClick(item,itemusage)" v-for="(itemusage,usageindex) in liTextArray1" :key="usageindex">{{ itemusage }}</div>
                            </div>
                            <input 
                              :disabled="meetinfo.blswitch || item.ischarge"
                              slot="reference" 
                              @focus="InputFocuszycfusageselect(index)"
                              @blur="Inputblurzycfusageselect(index)" 
                              v-model="item.usages" 
                              type="text" 
                              autocomplete="off" 
                              spellcheck="false" 
                              maxlength="500" 
                              placeholder="用法" 
                              class="zycfusage-input__inner">
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="zycfusage" style="text-align: center;">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                        <div class="bwhl-input-wrapper count-center">
                          <el-popover
                            placement="bottom"
                            :value="showPopupzycfdoseselect[index]"
                            trigger="manual">
                            <div class="selectcontainer">
                              <div class="column" @click="handlezycfdoseClick(item,itemusage)" v-for="(itemusage,index) in liTextArray2" :key="index">{{ itemusage }}</div>
                            </div>
                            <input 
                              :disabled="meetinfo.blswitch || item.ischarge"
                              slot="reference" 
                              @focus="InputFocuszycfdose(index)"
                              @blur="Inputblurzycfdose(index)" 
                              v-model="item.dose" 
                              type="text" 
                              autocomplete="off" 
                              spellcheck="false" 
                              maxlength="500" 
                              placeholder="剂日量" 
                              class="zycfusage-input__inner">
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="zycfusage" style="text-align: center;">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                        <div class="bwhl-input-wrapper count-center">
                          <el-popover
                            placement="bottom"
                            :value="showPopupuzycffrequency[index]"
                            trigger="manual">
                            <div class="selectcontainer">
                              <div class="column" @click="handlezycffrequencyClick(item,itemusage)" v-for="(itemusage,index) in liTextArray3" :key="index">{{ itemusage }}</div>
                            </div>
                            <input 
                              :disabled="meetinfo.blswitch || item.ischarge"
                              slot="reference" 
                              @focus="InputFocuszycffrequency(index)"
                              @blur="Inputblurzycffrequency(index)" 
                              v-model="item.frequency" 
                              type="text" 
                              autocomplete="off" 
                              spellcheck="false" 
                              maxlength="500" 
                              placeholder="次日量" 
                              class="zycfusage-input__inner">
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="zycfusage" style="text-align: center;">
                  <div class="bwhl-form-item is-required">
                    <div class="bwhl-form-item-content">
                      <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                        <div class="bwhl-input-wrapper count-center">
                          <el-popover
                            placement="bottom"
                            v-model="showPopupudosagetaken[index]"
                            trigger="manual">
                            <div class="selectcontainer">
                              <div class="column" @click="handlezycfdosagetakenClick(item,itemusage)" v-for="(itemusage,index) in liTextArray4" :key="index">{{ itemusage }}</div>
                            </div>
                            <input
                              :disabled="meetinfo.blswitch || item.ischarge"
                              style="width: 100px;" 
                              slot="reference" 
                              @focus="InputFocuszycfdosagetaken(index)"
                              @blur="blurzycfdosagetaken(index)" 
                              v-model="item.dosagetaken" 
                              type="text" 
                              autocomplete="off" 
                              spellcheck="false" 
                              maxlength="500" 
                              placeholder="服用量" 
                              class="zycfusage-input__inner">
                          </el-popover>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="zycfdose flexrow">
                  <div class="child-div">加工费 ¥</div>
                  <input :disabled="meetinfo.blswitch || item.ischarge" type="text" @click="handleInputClick" @input="processingpreiceInput(item,item.processingpreice)" v-model="item.processingpreice" placeholder="0.00" class="input-element">
                </div>
                <div class="flexrow inputnotes">
                  <input :disabled="meetinfo.blswitch || item.ischarge" type="text" v-model="item.notes" placeholder="备注" class="input-element">
                </div>
              </div>
            </div>
            <div class="flex-item align-right jend">
              <div class="flexrow mr10">
                <div class="mr10">{{ zhcnrecipequantity(index,item) }} 味</div>
                <div class="mr10">单剂 {{ zhcnrecipequantitytotal(index,item) }}g</div>
                <div class="mr10">总共 {{ zhcnrecipequantitytotal(index,item)*item.dosecount }}g</div>
              </div>
              <div>¥ {{ zhcnrecipetotalCost(index,item) }}</div>
            </div>
          </div>
        </el-card>
        <el-dialog
          :visible.sync="zycfdialogVisible"
          width="50%"
          :lock-scroll="true"
          :close-on-click-modal="false"
          custom-class="eldialog">
          <div class="eldialogbox">
            <div class="eldialogboxleft">
              <div class="left-header">
                <el-input
                  clearable
                  @input="searchmedicineTemplateInput"
                  @focus="searchmedicineTemplatefocus"
                  @clear="searchmedicineTemplateclear"
                  v-model="searchmedicineTemplatetext"
                  placeholder="请输入拼音或首字母或文字查询处方"
                  prefix-icon="el-icon-search">
                </el-input>
              </div>
              <div class="left-tabs" v-if="!searchmedicineTemplateswitch">
                <el-tabs class="left-tabs-box" v-model="zycfdemo" @tab-click="zycfdemoClick">
                  <el-tab-pane :label="item.name" :name="item._id" v-for="(item,index) in categoriesoptions" :key="index">
                  </el-tab-pane>
                </el-tabs>
                <div class="left-tabs-centent">
                  <div class="tabs-centent-left sketch_content">
                    <div class="boxli" v-for="(childrenitem,childrenkey) in childrenCategory" :key="childrenkey">
                      <i class="el-icon-s-order boxliicon"></i>
                      <div @click="changechildrenCategory(childrenitem,childrenkey)" :class="['activemedicineTemplate',{ activeitem: childrenkey === selectedIndex }]" style="width:100%;margin-left: 5px;padding-left: 5px;">{{childrenitem.name}}</div>
                    </div>
                  </div>
                  <div class="tabs-centent-right sketch_content">
                    <div class="boxli" v-for="(Template,indexkey) in medicineTemplate" :key="indexkey">
                      <i class="el-icon-document-copy boxliicon"></i>
                      <div @click="changemedicineTemplate(Template,indexkey)" :class="['activemedicineTemplate',{ activeitem: indexkey === childrenselectedIndex }]" style="width:100%;margin-left: 5px;padding-left: 5px;">{{ Template.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="left-tabs" v-if="searchmedicineTemplateswitch">
                <div v-if="searchmedicineTemplatedata.length === 0"><el-empty description="没有找到匹配的处方"></el-empty></div>
                <div v-if="searchmedicineTemplatedata.length > 0">
                  <div class="searchmedicinedatatotal">找到<span style="margin-left: 5px;margin-right: 5px; color:#3e68b5">{{ searchmedicineTemplatedata.length }}</span>条结果</div>
                  <div>
                    <div class="boxli" v-for="(searchitem,searchkey) in searchmedicineTemplatedata" :key="searchkey">
                        <i class="el-icon-s-order boxliicon"></i>
                        <div @click="searchchangemedicineTemplate(searchitem,searchkey)" :class="['activemedicineTemplate',{ activeitem: searchkey === searchkeyIndex }]" style="width:100%;margin-left: 5px;padding-left: 5px;">{{searchitem.name}}</div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="eldialogboxright" v-if="categoriesoptions.length > 0">
              <div class="right-header">
                <div class="righttitle">{{ isSelecteddata.name }}</div>
              </div>
              <div class="right-creater">
                <div class="">创建人：</div>
                <div class="">{{ isSelecteddata.Creator.name  }}</div>
              </div>
              <div class="sketch_content">
                <div class="zycftop">
                  <div class="useinfotop">
                    <div class="useinfoclass">分类</div>
                    <div class="oneuseinfoclasscentent">{{ isSelecteddata.category[0].name }}</div>
                  </div>
                  <div class="useinfotop">
                    <div class="useinfoclass">来源</div>
                    <div class="oneuseinfoclasscentent">{{ isSelecteddata.source }}</div>
                  </div>
                  <div class="useinfotop">
                    <div class="useinfoclass">功用</div>
                    <div class="oneuseinfoclasscentent">{{ isSelecteddata.effect }}</div>
                  </div>
                  <div class="lastuseinfotop">
                    <div class="useinfoclass">主治</div>
                    <div class="oneuseinfoclasscentent">{{ isSelecteddata.attending }}</div>
                  </div>
                </div>
                <div class="zycffoot">
                  <div class="right-useinfofoot">
                    <div class="useinfofoottitle">中药处方</div>
                    <div class="zycfmedicineCadn">
                      <div class="zycfmedicineCadnitem" v-for="(i,index) in isSelecteddata.ingredients">
                        <div>{{i.drug.fullName}}</div>
                        <div>{{i.quantity}}g</div>
                      </div>
                    </div>
                    <div class="lasefootitem">
                      <div class="totaldescription">
                        <div class="dose-count">
                          {{ isSelecteddata.dosecount }}剂
                        </div>
                        <div class="usages">
                          {{ isSelecteddata.usages }}
                        </div>
                        <div class="daily-dosage">
                          {{ isSelecteddata.dose }}
                        </div>
                        <div class="freq">
                          {{ isSelecteddata.frequency }}
                        </div>
                        <div class="usage-level">
                          {{ isSelecteddata.dosagetaken }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dialog-footer">
                <el-button @click="zycfdialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="usemedicineTemplate">确 定</el-button>
              </div>
            </div>
            <div class="eldialogboxright" v-if="categoriesoptions.length === 0">
              <el-empty description="还没有创建处方模版"></el-empty>
            </div>
          </div>
        </el-dialog>
        <!--中药处方-->
      </el-col>
      <!--中间-->
      <el-col :span="6" style="padding-left: 10px">
        <el-card
          class="historybox"
          shadow="hover"
          :body-style="{
            padding:0,
            'background-color':'none',
            height: '100%',
            display: 'flex',
            'flex-direction': 'column',
          }"
        >
        <el-tabs type="border-card">
          <el-tab-pane label="历史记录">
            <div v-if="visithistorydata.length === 0"><el-empty description="暂无记录"></el-empty></div>
            <div v-if="visithistorydata.length > 0">
              <el-collapse v-model="activeNames" @change="collapsehistoryChange">
                <el-collapse-item :name="visithistoryitem._id" v-for="(visithistoryitem,_id) in visithistorydata" :key="visithistoryitem._id">
                  <template slot="title">
                    <div class="collapsetitle">
                      <div class="ellipsis" style="width:220px;">{{ visithistoryitem.diagnosis }}</div>
                      <div class="flex_1" style="margin-left:10px;">{{ visithistoryitem.doctor }}</div>
                      <div class="flex_1">{{ formatDate(visithistoryitem.visittime) }}</div>
                    </div>
                  </template>
                  <div style="margin: 0 16px;">
                    <div class="collapsehistorydetail">
                      <div class="binglibtn">
                        <div>病历</div>
                        <el-button size="mini" @click="copybingli(visithistoryitem)">复制</el-button>
                      </div>
                      <div class="historyboxcolumn">
                        <div class="historyboxrow" v-if="visithistoryitem.patientdescribe">
                          <div>主诉：</div>
                          <div class="w280 chaochuyc">{{ visithistoryitem.patientdescribe }}</div>
                        </div>
                        <div class="historyboxrow"  v-if="visithistoryitem.historyofpresent">
                          <div>现病：</div>
                          <div class="w280 chaochuyc">{{ visithistoryitem.historyofpresent }}</div>
                        </div>
                        <div class="historyboxrow" v-if="visithistoryitem.auscultationandpalpation">
                          <div>望闻切诊：</div>
                          <div class="w280 chaochuyc">{{ visithistoryitem.auscultationandpalpation }}</div>
                        </div>
                        <div class="historyboxrow" v-if="visithistoryitem.diagnosis">
                          <div>诊断：</div>
                          <div  class="w280 chaochuyc">{{ visithistoryitem.diagnosis }}</div>
                        </div>
                        <div class="historyboxrow" v-if="visithistoryitem.dialectical">
                          <div>辨证：</div>
                          <div  class="w280 chaochuyc">{{ visithistoryitem.dialectical }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="collapsehistorydetail" v-if="visithistoryitem.consulting.length > 0">
                      <div class="binglibtn">
                        <div>诊疗项目</div>
                        <el-button size="mini" @click="copyzhiliaoxiangmu(visithistoryitem.consulting)">复制</el-button>
                      </div>
                      <div class="historyboxcolumn" v-for="(consultingitem,consultingindex) in visithistoryitem.consulting" :key="consultingindex">
                        <div class="historyboxrow flexgrow_1">
                          <div class="ellipsis" style="width:220px;">{{ consultingitem.fullName }}({{returntext(consultingitem.istruepay,consultingitem.ischarge)}})</div>
                          <div class="flex_1">{{ consultingitem.defaultcount }} {{ consultingitem.selectedPackageUnit }}</div>
                          <div>¥：{{ consultingitem.Totalsellingprice }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="collapsehistorydetail" v-if="visithistoryitem.westrecipe.length > 0">
                      <div class="binglibtn">
                        <div>中西成药</div>
                        <el-button size="mini" @click="copywestrecipe(visithistoryitem.westrecipe)">复制</el-button>
                      </div>
                      <div class="historyboxcolumn" v-for="(westrecipeitem,westrecipeindex) in visithistoryitem.westrecipe" :key="westrecipeindex">
                        <div class="historyboxrow flexgrow_1">
                          <div class="ellipsis" style="width:220px;">{{ westrecipeitem.fullName }}({{returntext(westrecipeitem.istruepay,westrecipeitem.ischarge)}})</div>
                          <div class="flex_1">{{ westrecipeitem.defaultcount }} {{ westrecipeitem.selectedPackageUnit }}</div>
                          <div>¥：{{ westrecipeitem.Totalsellingprice }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="collapsehistorydetail" v-if="visithistoryitem.zhcnrecipe.length > 0">
                      <div v-for="(zhcnrecipeitem,zhcnrecipeindex) in visithistoryitem.zhcnrecipe" :key="zhcnrecipeindex">
                        <div class="binglibtn">
                          <div>中药处方({{returntext(zhcnrecipeitem.istruepay,zhcnrecipeitem.ischarge)}})</div>
                          <el-button size="mini" @click="copyzhongyaochufang(zhcnrecipeitem)">复制</el-button>
                        </div>
                        <div class="historyboxcolumn">
                          <div class="historyboxrow flex_wrap">
                            <div class="totaldescription" v-for="(zhcnrecipedrug,zhcnrecipeitemkey) in zhcnrecipeitem.productInfos" :key="zhcnrecipeitemkey">
                              <div>{{ zhcnrecipedrug.fullName }}</div>
                              <div>{{ zhcnrecipedrug.grammage }} {{ zhcnrecipedrug.packageUnit }}</div>
                            </div>
                          </div>
                        </div>
                        <div class="binglibtn">
                          <div>{{zhcnrecipeitem.dosecount}}剂</div>
                          <div size="mini">¥：{{zhcnrecipeitem.totalPrice}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="historyboxcolumn">
                      <div class="binglibtn padbottom10">
                        <div>{{ visithistoryitem.visittime }}</div>
                        <div size="mini">
                          总计 ¥：{{ (visithistoryitem.consultingtotalCost || 0) + (visithistoryitem.westrecipetotalCost || 0) + (visithistoryitem.zhcnrecipetotalPrice || 0) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </el-tab-pane>
        </el-tabs>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog
      title="转诊"
      :visible.sync="changedoctorDialog"
      width="30%"
      class="changedoctorbox"
      center>
      <el-alert title="转诊将会保存现有病历，再转诊医生" type="info" :closable="false" show-icon></el-alert>
      <el-form class="mt10">
        <el-form-item label="转诊医生">
          <el-select @change="changedoctorselect" v-model="changedoctorid" clearable placeholder="请选择要转诊的医生" style="width: 200px;">
            <el-option
              v-for="item in changedoctorlist"
              :key="item._id"
              :label="item.name"
              :value="item._id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitchangedoctor">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="挂号"
      :visible.sync="newmeetvisitdialog"
      width="40%"
    >
      <div>
        <div class="newmeetvisittop">
          <el-select
            class="newmeetvisitselect"
            :disabled="isnewwxuser"
            v-model="serachwxuser"
            filterable
            remote
            placeholder="搜索患者"
            :remote-method="fnsearchwxuser"
            :loading="searchwxuserloading"
            @change="changewxuser"
            >
            <el-option
              v-for="item in searchwxuserptions"
              :key="item._id"
              :label="item.name"
              :value="item._id">
            </el-option>
          </el-select>
          <el-checkbox v-model="isnewwxuser" class="ml10" @input="fnisnewwxuser">新患者</el-checkbox>
        </div>
        <div class="justify-space-between newmeetvisittop mt10">
          <el-input
            placeholder="姓名"
            :disabled="!isnewwxuser"
            v-model="createduser.name">
            <i slot="prefix" class="el-input__icon el-icon-user-solid"></i>
          </el-input>
          <el-input
            placeholder="手机"
            :disabled="!isnewwxuser"
            v-model="createduser.mobile">
            <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
          </el-input>
          <el-input
            placeholder="年龄"
            :disabled="!isnewwxuser"
            v-model="createduser.age">
            <i slot="prefix" class="el-input__icon el-icon-orange"></i>
          </el-input>
          <div>
            <el-radio v-model="createduser.gender" :disabled="!isnewwxuser" :label="1">男</el-radio>
            <el-radio v-model="createduser.gender" :disabled="!isnewwxuser" :label="0">女</el-radio>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="newmeetvisitcss">
          选择门诊：<el-select class="newmeetvisitselect" v-model="newwxuser.clinicid" placeholder="请选择医生" @change="getClinicNameById(newwxuser.clinicid,newwxuser.clinicoptions)">
            <el-option
                v-for="item in newwxuser.clinicoptions"
                :key="item._id"
                :label="item.clinicname"
                :value="item._id">
            </el-option>
          </el-select>
          选择医生：<el-select class="newmeetvisitselect" v-model="newwxuser.doctorid" placeholder="请选择医生" @change="getUserNameById(newwxuser.doctorid,newwxuser.doctoroptions)">
            <el-option
                v-for="item in newwxuser.doctoroptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="newmeetvisitcss mt10">
          选择时段
        </div>
        <div class="newmeetvisitcss mt10">
          <el-radio-group v-model="schedulingtimeitem" @change="changeslotandnumdadad">
            <div v-if="newwxuser.slotandnum.length > 0">
              <el-radio v-for="(item, index) in newwxuser.slotandnum" :key="index" :label="item._id">{{ item.firsttime }}-{{ item.endtime }}</el-radio>
            </div>
            <div v-if="newwxuser.slotandnum.length === 0">
              <el-alert
                title="该医生当天没有开放预约"
                type="warning"
                :closable="false">
              </el-alert>
            </div>
          </el-radio-group>
        </div>
        <el-divider></el-divider>
        <div class="createorder align-center">
          挂号费：<el-input-number v-model="newwxuser.registerprice" controls-position="right"></el-input-number>
          <el-button class="ml10" type="primary" v-debounce size="medium" @click="createneworder">保 存</el-button>
        </div>    
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store";
import { BButton } from "bootstrap-vue";
import Moment from "moment";
import numberToChinese from "./numberToChinese.js"
import socket from "@/utils/socket";
export default {
  name: "index",
  components: {
    BButton,
  },
  data() {
    return {
      // 版本号
      version: "3.3.0",
      bodyStyle: { padding: '40px'},
      dynamicValidateForm: {
        domains: [
          {
            value: null,
          },
        ],
      },
      searchwxuserloading: false,
      searchwxuserptions:[],
      schedulingtimeitem:[],
      createduser:{
        _id:"",
        name:"",
        namefirstpy:"",
        namepy:"",
        relation:null,
        mobile:"",
        age:null,
        gender:null
      },
      createslotandnum:{
        _id:"",
        firsttime:"",
        endtime:"",
      },
      newwxuser:{
        doctor:"",
        doctorid:"",
        doctorname:"",
        doctoroptions:[],
        clinicid:"",
        clinicname:"",
        clinicoptions:[],
        slotandnum:[],
        registerprice:0
      },
      meetvisitsex:0,
      serachwxuser:"",
      isnewwxuser:false,
      newmeetvisitdialog:false,
      tableKey: 1,
      changedoctorlist:[],
      changedoctorneme:"",
      changedoctorid:"",
      orderinfo:{},
      changedoctorDialog:false,
      showPopuppatientdescribe:false,
      showPopuphistoryofpresent:false,
      showPopuppasthistory:false,
      showPopupallergyhistory:false,
      showPopupauscultationandpalpation:false,
      showPopupdiagnosis:false,
      showPopupdialectical:false,
      showPopupexhort:false,
      showPopupzhengliaoxiangmu:false,
      showPopupzhongxichengyao:false,
      showPopupusageselect:[],
      showPopupufreq:false,
      showPopupinspect:false,
      showPopupzhcnrecipe:[],
      showPopupzycfusageselect:[],
      showPopupzycfdoseselect:[],
      showPopupuzycffrequency:[],
      showPopupudosagetaken:[],
      showPopupupdatazhcnrecipe:[[]],
      displayNameItem:[[]],
      serachdisplayName: "",
      zycfdialogVisible:false,
      zycfdemo:'',
      activeNames: ['1'],
      searchpatientvalue:"",
      timer: null,//防抖参数
      showsearchpatientvalue:false,
      searchwesternmedicine:"",
      searchorderlist:[],
      categorizedData:{},
      showPopup:false,
      delbtn:false,
      showzhengliaoxiangmu: null,
      showDiv: null,
      searchchconsulting: "",
      selectedPackageUnit: '',
      searchZynameitem: '',
      multipleSelection:[],
      isMultiple:false,
      detailtitle: "",
      detaildata: [],
      loading: false,
      westdrugnum: 0,
      zhcndrugnum: 0,
      consultnum: 0,
      drugnummax: null,
      isdisabled: false,
      consultingdialog: false,
      dialogTableVisible: false,
      shortcutzhcndrugdialog: false,
      zhcndrugdialog: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      date: Moment(new Date()).format("YYYY-MM-DD"),
      chooceactive: "",
      searchdrug: "",
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据量
      total: 0, // 总数据量
      zhcncurrentPage: 1, // 当前页码
      zhcnpageSize: 10, // 每页数据量
      zhcntotal: 0, // 总数据量
      consultcurrentPage: 1, // 当前页码
      consultpageSize: 10, // 每页数据量
      consulttotal: 0, // 总数据量
      tableData: [],
      tableData1: [{
        title:"过敏史",
        content:"青霉素, 链霉素, 卡那霉素, 林可霉素, 头孢类, 磺胺类, 酒精, 碘伏, 去痛片, 扑热息痛, 安痛定, 安定, 鲁米那, 阿司匹林, 普鲁卡因, 花粉, 霉菌, 尘螨, 毛发皮屑, 牛奶, 鸡蛋, 大豆, 小麦, 花生, 鱼虾, 坚果"
      }],
      tableData2: [],
      zhcndrugData: [],
      consultData: [],
      adddrugs: [],
      meetinfo: {
        _id: "",
        openid: "",
        meetperson: "",
        age:null,
        mobile: "",
        idcard: "",
        gender: "",
        doctor: "",
        date: "",
        status: "",
        statusvisit: "",
        switch:true,
        blswitch:true,
        medicalrecord: {
          visituserid:"",
          visitusername:"",
          visittime:"",
          doctor:"",
          patientdescribe:"",//主诉
          historyofpresent:"",//现病史
          pasthistory:"",//既往史
          allergyhistory:"",//过敏史
          auscultationandpalpation:"",//望闻切诊
          diagnosis:"",//诊断
          inspect:"",//检查
          dialectical:"",//辩证
          exhort: "",//医嘱
          history: "",
          symptom: "",
          westrecipe: [],
          zhcnrecipe: [],
          prescribetitle: "",
          prescribenum: 1,
          consulting: [],
          consultingtotalCost:null,
          total: 0,
        },
      },
      content: {
        text1: "修改病历后，原来保存的将会覆盖，同时处方的药品数量也会还原",
        text2: "一般用在当患者签到后，又没有会诊，订单将会取消，患者需重新预约",
        text3: "保存后病历与处方将会保存",
        text4: "会诊完成，可以接待下个患者",
      },
      orderlist: [],
      options: [],
      selectiondrugs: [],
      selectionzhcndrug: [],
      selectionconsulting: [],
      savedrugs: [],
      savezhcndrugs: [],
      saveconsulting: [],
      saveconsultingdata: [],
      arrhistory: [],
      historylist: [],
      arrsymptom: [],
      symptomlist: [],
      arrdiagnosis: [],
      diagnosislist: [],
      arradvice: [],
      advicelist: [],
      optionshistory: [],
      optionssymptom: [],
      optionsdiagnosis: [],
      optionsadvice: [],
      arrshortcutzhcndrug: [],
      optionsshortcutzhcndrug: [],
      shortcutzhcndruglist: [],
      outerVisible: false,
      innerVisible: false,
      visithistory:[],
      visithistorydata:[],
      historydetail:{},
      listQuery:{},
      xxx:[],
      xxxx:[],
      patients: [
        { name: 'Patient 1', medicalRecord: '' },
        { name: 'Patient 2', medicalRecord: '' },
        // ...
      ],
      gridDataceshi1:[],
      gridDataceshi2:[],
      gridDataceshi3:[],
      usageselect:{
        internaluse:["口服","含服","嚼服","晨服","餐前服","餐中服","餐后服","睡前服"],
        injection:["静脉滴注", "静脉注射","肌内注射","腔内注射","雾化吸入","皮下注射","皮内注射","穴位注射","直肠滴注","局部注射","局部麻醉","超声透药"],
        externaluse:["溶媒用","外用","滴眼","滴鼻","滴耳","口腔喷入","鼻腔喷入","含漱","涂抹","塞肛","直肠给药","阴道给药"]
      },
      freqselect:[
        { frequency: '每天1次', multiplier: 1, name: 'qd' },
        { frequency: '每天2次', multiplier: 2, name: 'bid' },
        { frequency: '每天3次', multiplier: 3, name: 'tid' },
        { frequency: '每天4次', multiplier: 4, name: 'qid' },
        { frequency: '隔日1次', multiplier: 0.5, name: 'qod' },
        { frequency: '每周1次', multiplier: 1 / 7, name: 'qw' },
        { frequency: '每周2次', multiplier: 2 / 7, name: 'biw' },
        { frequency: '每2小时1次', multiplier: 12, name: 'q2h' },
        { frequency: '每4小时1次', multiplier: 6, name: 'q4h' },
        { frequency: '每6小时1次', multiplier: 4, name: 'q6h' },
        { frequency: '每8小时1次', multiplier: 3, name: 'q8h' },
        { frequency: '每12小时1次', multiplier: 2, name: 'q12h' },
      ],
      liTextArray1:[ "煎服", "冲服", "制膏", "制丸", "外用", "贴敷", "打粉", "泡饮", "泡酒", "泡浴", "泡脚", "擦拭" ],
      liTextArray2:[ "1日1剂", "1日2剂", "2日1剂", "1日半1剂"],
      liTextArray3:[ "1日1次", "1日2次", "1日3次", "1日4次", "1日5次", "1日6次", "2日1次", "隔日1次", "1周1次", "1周2次", "1周3次", "隔周1次", "必要时", "顿服", "少量频服", "随时服用" ],
      liTextArray4:[ "每次10ml", "每次20ml", "每次30ml", "每次40ml", "每次50ml", "每次60ml", "每次70ml", "每次80ml", "每次90ml", "每次100ml", "每次120ml", "每次150ml", "每次180ml", "每次200ml", "每次250ml", "每次300ml" ],
      inputValue:"",
      visible: false,
      showDeleteIcon: [],
      showDeleteIcon1: [],
      zhcnrecipearrayindex:null,//父索引
      zhcnrecipearraykeyid:null,//子索引
      displayNameFocusCount:0,
      categoriesoptions: [],
      childrenCategory: [],
      medicineTemplate: [],
      selectedIndex:0,
      searchkeyIndex:0,
      childrenselectedIndex:0,
      isSelecteddata:{
        name:"",
        category:[{name:""}],
        Creator:{},
        ingredients:[],
        effect:"",
        source:"",
        attending:"",
        dosecount:"",
        usages:"",
        dose:"",
        frequency:"",
        usages:"",
        dosagetaken:"",
      },
    zhcnrecipeindex:0,
    searchmedicineTemplateswitch:false,
    searchmedicineTemplatetext:"",
    searchmedicineTemplatedata:[],
    medicineTemplatefocus:false,
    sexoptions:[{
        value: 1,
        label: '男'
      }, {
        value: 0,
        label: '女'
      }, 
    ]
    }
  },
  watch:{
    searchmedicineTemplatetext(newVal) {  
      if (newVal === '') {  
        this.searchmedicineTemplateswitch = false
        this.getchildrenCategory()  
      }  
    }  
  },
  methods: {
    createneworder(){
      const data = {
        isnewwxuser:this.isnewwxuser,
        _id:this.createduser._id,
        name:this.createduser.name,
        namefirstpy:this.createduser.namefirstpy,
        namepy:this.createduser.namepy,
        relation:this.createduser.relation,
        mobile:this.createduser.mobile,
        age:this.createduser.age,
        gender:this.createduser.gender,
        doctorid:this.newwxuser.doctorid,
        doctorname:this.newwxuser.doctorname,
        clinicid:this.newwxuser.clinicid,
        clinicname:this.newwxuser.clinicname,
        slotandnumid:this.createslotandnum._id,
        firsttime:this.createslotandnum.firsttime,
        endtime:this.createslotandnum.endtime,
        registerprice:this.newwxuser.registerprice
      }
      if(!this.createduser.name || !this.createduser.mobile || this.createduser.gender === null){
        return this.$message.error('姓名，手机，性别，年龄不能为空')
      }
      if(!this.createslotandnum._id){
        return this.$message.error('你没有选择时段')
      }
      this.$api.meetorder.createneworder(data).then(res =>{
        if(res.data.code === 200){
          this.newmeetvisitdialog = false
          this.createduser = {}
          this.serachwxuser = {}
          this.newwxuser.doctor = ""
          this.newwxuser.doctorid = ""
          this.newwxuser.doctorname = ""
          this.newwxuser.clinicid = ""
          this.newwxuser.clinicname = ""
          this.newwxuser.registerprice = 0
          this.createslotandnum = {}
          return this.$message({
            message: '挂号成功',
            type: 'success'
          });
        }else{
          this.$message({
            message: '挂号失败',
            type: 'warning'
          });
        }
      })
    },
    fnisnewwxuser(e){
      if(e === true){
        this.serachwxuser = ""
        this.createduser = {}
      }
    },
    getUserNameById(id,data) {  
      const user = data.find(item => item.value === id)
      this.newwxuser.doctorname = user ? user.label : null;
      this.getchangeslotandnum()
    }, 
    getClinicNameById(id,data) {  
      const clinic = data.find(item => item._id === id)
      this.newwxuser.clinicname = clinic ? clinic.clinicname : null;
      this.getchangeslotandnum()
    }, 
    changewxuser(id){
      if(id){
        const userarr = this.searchwxuserptions.filter(item => item._id === id)
        this.createduser = userarr[0]
      }
    },
    fnsearchwxuser(searchtext){
      if(searchtext !== ''){
        this.searchwxuserloading = true;
        setTimeout(() => {
          this.searchwxuserloading = false;
          this.$api.meetorder.searchwxuserinfamily(searchtext).then(res =>{
            this.searchwxuserptions = res.data.data
          })
        }, 200);
      }else {
          this.searchwxuserptions = [];
      }
    },
    changeslotandnumdadad(e){
      const slotandnum = this.newwxuser.slotandnum.find(item => item._id === e)
      if(slotandnum){
        this.createslotandnum._id = slotandnum._id
        this.createslotandnum.firsttime = slotandnum.firsttime
        this.createslotandnum.endtime = slotandnum.endtime
      }
    },
    getchangeslotandnum(){
      const data = {
        clinicnameid:this.newwxuser.clinicid,
        doctor:this.newwxuser.doctorid
      }
      this.$api.meetorder.gettodayscheduling(data).then(res =>{
        if(res.data.data && res.data.data.extendedProps.slotandnum.length > 0){
          this.newwxuser.slotandnum = res.data.data.extendedProps.slotandnum
          this.newwxuser.registerprice = res.data.data.extendedProps.registerprice
        }else{
          this.newwxuser.slotandnum = []
          this.newwxuser.registerprice = 0
        }
      })
    },
    opennewmeetvisit(){
      this.newmeetvisitdialog = true
      const data = {
        clinicnameid:this.newwxuser.clinicid,
        doctor:this.newwxuser.doctorid
      }
      this.$api.meetorder.gettodayscheduling(data).then(res =>{
        if(res.data.data && res.data.data.extendedProps.slotandnum.length > 0){
          this.newwxuser.slotandnum = res.data.data.extendedProps.slotandnum
          this.newwxuser.registerprice = res.data.data.extendedProps.registerprice
        }else{
          this.newwxuser.slotandnum = []
          this.newwxuser.registerprice = 0
        }
      })
    },
    listclinic(){
      this.$api.clinic.list().then(res =>{
        this.newwxuser.clinicoptions = res.data.data
        this.newwxuser.clinicid = this.newwxuser.clinicoptions[0]._id
        this.newwxuser.clinicname = this.newwxuser.clinicoptions[0].clinicname
      })
    },
    async getdoctor(){
      if(store.state.doctor.doctorlist.length === 0){
        this.newwxuser.doctoroptions =await store.dispatch('doctor/fetchDoctorlist')
      }else{
        this.newwxuser.doctoroptions = store.state.doctor.doctorlist
      }
      this.newwxuser.doctorid = this.newwxuser.doctoroptions[0].value
      this.newwxuser.doctorname = this.newwxuser.doctoroptions[0].label
    },
    returntext(num,bln){
      if(num === 0 && bln === false){
        return "未付款"
      }
      if(num > 0 && bln === true){
        return "已付款"
      }
      if(num > 0 && bln === false){
        return "已退款"
      }
    },
    recallnum(){
      const visitnum = this.meetinfo.mobile.slice(-4)
      const message = {
          visitnum:visitnum,
          meetperson:this.meetinfo.meetperson,
      }
      this.$api.visit.recallnum(message).then((res) => {
        this.$message({
          type: "success",
          message: "再次播报叫号中",
        });
      }).catch((error) =>{
        this.$message.error('网络异常');
      });
    },
    changedoctorselect(item){
      var obj={}
      obj= this.changedoctorlist.find(function(i){
        return i._id === item
      });
      const {_id,name} = obj
      this.changedoctorneme = name
    },
    submitchangedoctor(){
      const data = {
        _id: this.meetinfo._id,
        openid: this.meetinfo.openid,
        gender: this.meetinfo.gender,
        age: this.meetinfo.age,
        medicalrecord: this.meetinfo.medicalrecord,
      }
      const doctor = {
        changedoctorid:this.changedoctorid,
        changedoctorneme:this.changedoctorneme
      }
      if(!this.changedoctorid && !this.changedoctorneme){
        return this.$message({
          type: "danger",
          message: "请选择转诊医生",
        });
      }
      this.$api.visit.submitchangedoctor(data,doctor,this.orderinfo).then((res) => {
        if (res.data.code === 200) {
          this.changedoctorDialog = false
          this.meetinfo.blswitch = true
          this.chooceclass(this.chooceactive, res.data.data)
          this.agreeChange(this.meetinfo._id, true, 'blswitch');
          this.agreeChange(this.meetinfo._id, 'finish', 'statusvisit');
          this.$message({
            type: "success",
            message: res.data.msg,
          });
        }else{
          this.$alert(res.data.msg, '发生错误', {
            confirmButtonText: '确定',
          });
        }
      }).catch((error) =>{
        console.log(error)
      });
    },
    openchangedoctorDialog(){
      if(!this.meetinfo.medicalrecord.patientdescribe){
        return this.$message({
          type: "danger",
          message: "主诉不能为空",
        });
      }
      if(!this.meetinfo.medicalrecord.diagnosis){
        return this.$message({
          type: "danger",
          message: "诊断不能为空",
        });
      }
      let allDefaultconsulting = this.meetinfo.medicalrecord.consulting.every(item => item.defaultcount > 0); 
      let allDefaultwestrecipe = this.meetinfo.medicalrecord.westrecipe.every(item => item.defaultcount > 0); 
      let allDefaultzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.dosecount > 0);
      let zhcnrecipeproductInfos = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.productInfos.every(info => info.grammage > 0));   
      if(!allDefaultconsulting || !allDefaultwestrecipe || !allDefaultzhcnrecipe || !zhcnrecipeproductInfos){
        return this.$message({
          type: "danger",
          message: "总量不能为空",
        });
      }
      this.changedoctorDialog = true
    },
    getchangedoctorlist(){
      this.$api.sysusers.changedoctorlist().then((res) => {
        console.log(res)
        if (res.data.code === 200 && res.data.data.length > 0) {
          this.changedoctorlist = res.data.data
        }else{
          this.changedoctorlist = []
        }
      }).catch((error) =>{
        console.log(error)
      });
    },
    cancelmedicalrecordswitch(data){
      this.meetinfo.blswitch = true
      this.agreeChange(this.meetinfo._id, true, 'blswitch');
    },
    copyzhongyaochufang(data){
      console.log(data)
      if(this.meetinfo.blswitch){
        return this.$alert("该病历为不可编辑的状态", '提示', {
          confirmButtonText: '确定',
        });
      }
      data.ischarge = false;
      this.meetinfo.medicalrecord.zhcnrecipe.push(data);
      console.log(this.meetinfo.medicalrecord.zhcnrecipe)
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.zhcnrecipe, 'medicalrecord.zhcnrecipe');
    },
    copyzhiliaoxiangmu(data){
      if(this.meetinfo.blswitch){
        return this.$alert("该病历为不可编辑的状态", '提示', {
          confirmButtonText: '确定',
        });
      }
      this.meetinfo.medicalrecord.consulting.push(...data);
      console.log(this.meetinfo.medicalrecord.consulting)
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.consulting, 'medicalrecord.consulting');
    },
    copywestrecipe(data){
      if(this.meetinfo.blswitch){
        return this.$alert("该病历为不可编辑的状态", '提示', {
          confirmButtonText: '确定',
        });
      }
      this.meetinfo.medicalrecord.westrecipe.push(...data);
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.westrecipe, 'medicalrecord.westrecipe');
    },
    copybingli(data){
      if(this.meetinfo.blswitch){
        return this.$alert("该病历为不可编辑的状态", '提示', {
          confirmButtonText: '确定',
        });
      }
      const {patientdescribe,historyofpresent,pasthistory,allergyhistory,inspect,auscultationandpalpation,diagnosis,dialectical,exhort} = data
      this.$confirm("复制将会覆盖原来所填写的病历", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定",
        cancelButtonText: "关闭",
      }).then(() => {
        this.meetinfo.medicalrecord.patientdescribe = patientdescribe
        this.meetinfo.medicalrecord.historyofpresent = historyofpresent
        this.meetinfo.medicalrecord.pasthistory = pasthistory
        this.meetinfo.medicalrecord.allergyhistory = allergyhistory
        this.meetinfo.medicalrecord.inspect = inspect
        this.meetinfo.medicalrecord.auscultationandpalpation = auscultationandpalpation
        this.meetinfo.medicalrecord.diagnosis = diagnosis
        this.meetinfo.medicalrecord.dialectical = dialectical
        this.meetinfo.medicalrecord.exhort = exhort
      }).catch((action) => {
        this.$message({
            type: "info",
            message: action === "cancel" ? "放弃复制" : "关闭弹窗",
          });
      })
    },
    formatDate(dateString) {  
      var dateParts = dateString.split(' ');  
      var formattedDate = dateParts[0].split('-'); 
      var formattedDate1 = formattedDate[1] + '-' + formattedDate[2]; 
      return formattedDate1;  
    },  
    editmedicalrecordswitch(data){
      this.meetinfo.blswitch = false
      this.agreeChange(this.meetinfo._id, false, 'blswitch');
    },
    editsavemedicalrecordswitch(data){
      if(!this.meetinfo.medicalrecord.patientdescribe){
        return this.$message({
          type: "danger",
          message: "主诉不能为空",
        });
      }
      if(!this.meetinfo.medicalrecord.diagnosis){
        return this.$message({
          type: "danger",
          message: "诊断不能为空",
        });
      }
      let allDefaultconsulting = this.meetinfo.medicalrecord.consulting.every(item => item.defaultcount > 0); 
      let allDefaultwestrecipe = this.meetinfo.medicalrecord.westrecipe.every(item => item.defaultcount > 0); 
      let allDefaultzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.dosecount > 0);
      let zhcnrecipeproductInfos = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.productInfos.every(info => info.grammage > 0));   
      if(!allDefaultconsulting || !allDefaultwestrecipe || !allDefaultzhcnrecipe || !zhcnrecipeproductInfos){
        return this.$message({
          type: "danger",
          message: "总量不能为空",
        });
      }
      this.$confirm("点击保存将会更新病例以及处方", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "保存",
        cancelButtonText: "关闭",
      })
        .then(() => {
          const data = {
            _id: this.meetinfo._id,
            openid: this.meetinfo.openid,
            gender: this.meetinfo.gender,
            age: this.meetinfo.age,
            medicalrecord: this.meetinfo.medicalrecord,
          };
          console.log(this.meetinfo.medicalrecord)
          this.$api.visit.editsavemedicalrecordswitch(data).then((res) => {
            if (res.data.code === 200) {
              this.chooceclass(this.chooceactive, res.data.data)
              this.agreeChange(this.meetinfo._id, true, 'blswitch');
              this.$message({
                type: "success",
                message: res.data.msg,
              });
            }else{
              this.$alert(res.data.msg, '发生错误', {
                confirmButtonText: '确定',
              });
            }
          });
        })
        .catch((action) => {
          this.isdisabled = true;
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃保存" : "关闭弹窗",
          });
        });
    },
    searchmedicineTemplatefocus(){
    },
    searchmedicineTemplateclear(){
      if(!this.searchmedicineTemplatetext){
        this.searchmedicineTemplateswitch = false
        this.getchildrenCategory()
      }
    },              
    searchmedicineTemplateInput(){
      if(this.searchmedicineTemplatetext){
        this.debounce(this.searchmedicineTemplate(), 500);
      }
    },
    searchmedicineTemplate(){
      const data = {
        search:this.searchmedicineTemplatetext
      }
      this.$api.templates.searchmedicineTemplate(data).then((res) => {
        if(res.data.code === 200 && res.data.data.length > 0){
          this.searchmedicineTemplateswitch = true
          this.searchmedicineTemplatedata = res.data.data
          this.searchkeyIndex = 0
          this.isSelecteddata = this.searchmedicineTemplatedata[0]
        }else{
          this.searchmedicineTemplateswitch = true
          this.searchmedicineTemplatedata = []
        }
      })
    },
    openmedicineTemplate(index){
      this.zycfdialogVisible = true
      this.zhcnrecipeindex = index    
    },
    usemedicineTemplate(index){
      let mergedArray = this.isSelecteddata.ingredients.map(({ _id, ...item }) => {
        const goodid = item.drug._id
        delete item.drug._id;  
        return {  
          ...item.drug, 
          goodid:goodid, 
          grammage: item.quantity  
        };  
      });
      
      // 使用 $set 方法更新对象属性  
      const data = {  
        dosagetaken: this.isSelecteddata.dosagetaken,  
        dose: this.isSelecteddata.dose,  
        dosecount: this.isSelecteddata.dosecount,  
        frequency: this.isSelecteddata.frequency,  
        usages: this.isSelecteddata.usages,  
        ischarge: false,  
        istruepay: 0,  
        productInfos: mergedArray  
      };  
      this.$set(this.meetinfo.medicalrecord.zhcnrecipe, this.zhcnrecipeindex, data);  
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.zhcnrecipe, 'medicalrecord.zhcnrecipe');  
      this.zycfdialogVisible = false;  
    },
    searchchangemedicineTemplate(data,index){
      this.searchkeyIndex = index
      this.isSelecteddata = data
    },
    changemedicineTemplate(data,index){
      this.childrenselectedIndex = index
      this.isSelecteddata = data
    },
    changechildrenCategory(data,index){
      this.selectedIndex = index
      const dataid = {
        _id:data._id
      }
      this.$api.templates.getCategoryidmedicineTemplate(dataid).then((res) => {
        if(res.data.code === 200 && res.data.medicineTemplate.length > 0){
          this.medicineTemplate = res.data.medicineTemplate
          this.childrenselectedIndex = 0
          this.isSelecteddata = this.medicineTemplate[this.childrenselectedIndex]
        }else{
          this.isSelecteddata = {
            name:"",
            category:[{name:""}],
            Creator:{},
            ingredients:[],
            effect:"",
            source:"",
            attending:"",
            dosecount:"",
            usages:"",
            dose:"",
            frequency:"",
            usages:"",
            dosagetaken:"",
          }
          this.medicineTemplate = []
        }
      })
    },
    getchildrenCategory() {
      const data = {
        _id:this.zycfdemo
      }
      this.$api.templates.getchildrenCategory(data).then((res) => {
        if(res.data.code === 200 && res.data.medicineTemplate.length > 0 ){
          this.childrenCategory = res.data.category.children
          this.medicineTemplate = res.data.medicineTemplate
          this.selectedIndex = 0
          this.childrenselectedIndex = 0
          this.isSelecteddata = this.medicineTemplate[this.childrenselectedIndex]
        }else{
          this.childrenCategory = []
          this.isSelecteddata = {
            name:"",
            category:[{name:""}],
            Creator:{},
            ingredients:[],
            effect:"",
            source:"",
            attending:"",
            dosecount:"",
            usages:"",
            dose:"",
            frequency:"",
            usages:"",
            dosagetaken:"",
          }
          this.medicineTemplate = []
        }
      });
    },
    getpermissionscategories() {
      this.$api.templates.getpermissionscategories().then((res) => {
        if(res.data.data.length > 0){
          this.categoriesoptions = res.data.data
          this.zycfdemo = this.categoriesoptions[0]._id
          this.getchildrenCategory()
        }
      }).catch((error) =>{
        console.log(error)
      })
    },
    //防抖的函数
    debounce(func, delay) {
      clearTimeout(this.timer);
      this.timer = setTimeout(func, delay);
    },
    //防抖
    searchphoneororderInput() {
      this.debounce(this.searchphoneororder, 500);
    },
    searchphoneororderblur() {
      if(this.searchpatientvalue === '')
      this.showsearchpatientvalue = false
    },
    //搜索患者
    searchphoneororder(){
      if(!this.searchpatientvalue){
        return this.showsearchpatientvalue = false
      }
      this.$api.meetorder.searchphoneororder(this.searchpatientvalue).then((res) => {
        this.showsearchpatientvalue = true
        if(res.data.code === 200){
          this.searchorderlist = res.data.data
        }else{
          this.searchorderlist = []
        }
      });
    },
    collapsehistoryChange(val) {
      console.log(val);
    },
    zycfdemoClick(tab, event) {
      console.log(tab.name);
      this.zycfdemo = tab.name
      this.getchildrenCategory()

    },
    getRefName(index,keyid) {
      return `searchNameInputRef_${index}_${keyid}`;
    },
    grammagefocusRefs(index,keyid) {
      return `grammageInputref_${index}_${keyid}`;
    },
    InputFocuszycfdosagetaken(index){
      this.$set(this.showPopupudosagetaken, index, true);
    },
    blurzycfdosagetaken(index){
      this.$set(this.showPopupudosagetaken, index, false);
    },
    InputFocuszycffrequency(index){
      this.$set(this.showPopupuzycffrequency, index, true);
    },
    Inputblurzycffrequency(index){
      this.$set(this.showPopupuzycffrequency, index, false);
    },
    InputFocuszycfdose(index){
      this.$set(this.showPopupzycfdoseselect, index, true);
    },
    Inputblurzycfdose(index){
      this.$set(this.showPopupzycfdoseselect, index, false);
    },
    InputFocuszycfusageselect(index){
      this.$set(this.showPopupzycfusageselect, index, true);
    },
    Inputblurzycfusageselect(index){
      this.$set(this.showPopupzycfusageselect, index, false);
    },
    handleInputFocususage(index){
      this.$set(this.showPopupusageselect, index, true);
    },
    handleInputblurusage(index){
      this.$set(this.showPopupusageselect, index, false);
    },
    handleMouseEnter(index,keyid) {
      this.$set(this.showDeleteIcon, index, true);
      this.$set(this.showDeleteIcon1, keyid, true);
    },
    handleMouseLeave(index,keyid) {
      this.$set(this.showDeleteIcon, index, false);
      this.$set(this.showDeleteIcon1, keyid, false);
    },
    zhcnrecipeInputFocus(index) {
      // 根据索引设置相应的 el-popover 显示状态为 true
      this.zhcnrecipearrayindex = index
      this.$set(this.showPopupzhcnrecipe, index, true);
      this.searchvisitchinesemedicine()
    },
    zhcnrecipefocusInput(index) {
      // 根据索引设置相应的 el-popover 显示状态为 false
      this.$set(this.showPopupzhcnrecipe, index, false);
    },
    updatazhcnrecipeInputFocus(index,keyid) {
      console.log("获取焦点了")
      this.zhcnrecipearrayindex = index
      this.zhcnrecipearraykeyid = keyid
      this.$refs[`zhcnrecipepopover_${index}_${keyid}`][0].doShow();
      console.log(this.$refs[`zhcnrecipepopover_${index}_${keyid}`])
    },
    updatazhcnrecipeblurInput(index,keyid) {
      console.log("没有焦点了")
      this.zhcnrecipearrayindex = index
      this.zhcnrecipearraykeyid = keyid
      this.$refs[`zhcnrecipepopover_${index}_${keyid}`][0].doClose();
      var Zynameitem = document.getElementById(`Zynameitem${index}${keyid}`);  
      var displayName = document.getElementById(`displayName${index}${keyid}`);
      Zynameitem.style.display = "block"
      displayName.style.display = "none"  
    },
    displayNamefocusInput(index,keyid,searchname){
      this.zhcnrecipearrayindex = index
      this.zhcnrecipearraykeyid = keyid
      var Zynameitem = document.getElementById(`Zynameitem${index}${keyid}`);  
      var displayName = document.getElementById(`displayName${index}${keyid}`);
      Zynameitem.style.display = "none"
      displayName.style.display = "block"  
      this.serachdisplayName = searchname
      this.searchvisitserachdisplayNamemedicine()
      //this.$refs[`zhcnrecipepopover_${index}_${keyid}`][0].doShow();
      this.$nextTick(() => {
        const refName = `inputFocus_${index}_${keyid}`
        console.log('refName',refName)
        console.log(document.getElementById(refName));
        const inputElement = document.getElementById(refName);
        if (inputElement) {
          inputElement.focus();
        }
      });
      //this.displayNameFocusCount++; // Increment the counter
      //console.log('displayNameFocusCount:', this.displayNameFocusCount);
    },
    numberToWords(num){
      return numberToChinese.numberToChinese(num)
    },
    addzhcnrecipeArray() {
      this.meetinfo.medicalrecord.zhcnrecipe.push({
        productInfos:[],
        dosecount: 1,
        usages: "煎服",
        dose:"1日1剂",
        frequency:"1日1次",
        dosagetaken:"每次150ml",
        processingpreice:0,
        notes:"",
        totalPrice:0.00,
        ischarge:false,
        istruepay:0
      });
      this.showPopupupdatazhcnrecipe.push([]);
      this.displayNameItem.push([]);
    },
    handlezxcySelectChange(item){
      if (item.selectedPackageUnit === item.packageUnit){
        console.log("调了这里11")
        if(item.selecteddosageUnit === item.medicineDosageUnit){
          console.log("调了这里12")
          const totalPackage = item.multiplier * item.dosage * item.defaultdays;
          const yaopingguigeUnit = item.medicineDosageNum * item.pieceNum
          const bottlesNeeded = Math.ceil(totalPackage / yaopingguigeUnit); 
          item.defaultcount = bottlesNeeded;
          return item.packagePrice;
        }else{
          console.log("调了这里13")
          const totalPackage = item.multiplier * item.dosage * item.defaultdays;
          const bottlesNeeded = Math.ceil(totalPackage / item.pieceNum); 
          item.defaultcount = bottlesNeeded;
          return item.piecePrice;
        }
      }else{
        console.log("调了这里21")
        if(item.selecteddosageUnit === item.medicineDosageUnit){
          console.log("调了这里22")
          const totalPackage = item.multiplier * item.dosage * item.defaultdays;
          const bottlesNeeded = Math.ceil(totalPackage / item.pieceNum); 
          item.defaultcount = bottlesNeeded;
          return item.packagePrice;
        }else{
          console.log("调了这里24")
          const totalPackage = item.multiplier * item.dosage * item.defaultdays;
          const bottlesNeeded = Math.ceil(totalPackage); 
          item.defaultcount = bottlesNeeded;
          return item.packagePrice;
        }
      }
      // if (item.selectedPackageUnit === item.packageUnit) {
      //   const totalPackage = item.multiplier * item.dosage * item.defaultdays;
      //   const yaopingguigeUnit = item.medicineDosageNum * item.pieceNum
      //   const bottlesNeeded = Math.ceil(totalPackage / yaopingguigeUnit); 
      //   item.defaultcount = bottlesNeeded;
      //   return item.packagePrice;
      // }else if(item.selectedPackageUnit === item.pieceUnit){
      //   const totalPackage = item.multiplier * item.dosage * item.defaultdays;
      //   const bottlesNeeded = Math.ceil(totalPackage / item.pieceNum); 
      //   item.defaultcount = bottlesNeeded;
      //   return item.piecePrice;
      // }
    },
    handlechangefreqs(val){
      console.log(val)
      val.item.freq = val.command
      val.item.multiplier = val.multiplier
      if(val.item.dismounting === 1){
        if(val.item.multiplier && val.item.dosage && val.item.defaultdays && val.item.defaultdays && val.item.selectedPackageUnit){
          val.item.selectedPackageUnit = val.item.pieceUnit
          if(val.item.selecteddosageUnit === val.item.medicineDosageUnit){
            const totalPackage = val.item.multiplier * val.item.dosage * val.item.defaultdays;
            const bottlesNeeded = Math.ceil(totalPackage / val.item.medicineDosageNum); 
            val.item.defaultcount = bottlesNeeded;
          }else if(val.item.selecteddosageUnit === val.item.pieceUnit){
            const totaldosage = val.item.dosage * val.item.defaultdays * val.item.multiplier; // 计算总服用量
            val.item.defaultcount = Math.ceil(totaldosage); // 计算需要的瓶数，向上取整
          }
        }
      }else{
        if(val.item.multiplier && val.item.dosage && val.item.defaultdays && val.item.defaultdays && val.item.selectedPackageUnit){
          val.item.selectedPackageUnit = val.item.packageUnit
          if(val.item.selecteddosageUnit === val.item.medicineDosageUnit){
            const totalPackage = val.item.multiplier * val.item.dosage * val.item.defaultdays;
            const yaopingguigeUnit = val.item.medicineDosageNum * val.item.pieceNum
            const bottlesNeeded = Math.ceil(totalPackage / yaopingguigeUnit); 
            val.item.defaultcount = bottlesNeeded;
          }else if(val.item.selecteddosageUnit === val.item.pieceUnit){
            const totaldosage = val.item.dosage * val.item.defaultdays * val.item.multiplier; // 计算总服用量
            val.item.defaultcount = Math.ceil(totaldosage / val.item.pieceNum); // 计算需要的瓶数，向上取整
          }
        }
      }
    },
    changefreqs(item,command,multiplier){
      return {
        item,
        command,
        multiplier
      }
    },
    updateDefaultCount(item) {
      if(item.defaultdays === 0 || item.defaultfrequency === 0){
        item.defaultcount = 0
      }else{
        item.defaultcount = item.defaultdays * item.defaultfrequency;
      }
    },
    updatewestrecipeCount(item) {
      if(item.dismounting === 1){
        if(item.multiplier && item.dosage && item.defaultdays && item.defaultdays && item.selectedPackageUnit){
          item.selectedPackageUnit = item.pieceUnit
          if(item.selecteddosageUnit === item.medicineDosageUnit){
            const totalPackage = item.multiplier * item.dosage * item.defaultdays;
            const bottlesNeeded = Math.ceil(totalPackage / item.medicineDosageNum); 
            item.defaultcount = bottlesNeeded;
          }else if(item.selecteddosageUnit === item.pieceUnit){
            const totaldosage = item.dosage * item.defaultdays * item.multiplier; // 计算总服用量
            item.defaultcount = Math.ceil(totaldosage); // 计算需要的瓶数，向上取整
          }
        }
      }else{
        if(item.multiplier && item.dosage && item.defaultdays && item.defaultdays && item.selectedPackageUnit){
          item.selectedPackageUnit = item.packageUnit
          if(item.selecteddosageUnit === item.medicineDosageUnit){
            const totalPackage = item.multiplier * item.dosage * item.defaultdays;
            const yaopingguigeUnit = item.medicineDosageNum * item.pieceNum
            const bottlesNeeded = Math.ceil(totalPackage / yaopingguigeUnit); 
            item.defaultcount = bottlesNeeded;
          }else if(item.selecteddosageUnit === item.pieceUnit){
            const totaldosage = item.dosage * item.defaultdays * item.multiplier; // 计算总服用量
            item.defaultcount = Math.ceil(totaldosage / item.pieceNum); // 计算需要的瓶数，向上取整
          }
        }
      }
    },
    handleInputClick(event) {
      event.target.select(); // 选中输入框的内容
    },
    setDefaultfrequency(item) {
      if (!item.defaultfrequency) {
        item.defaultfrequency = 1;
      }
    },
    deleteconsultingItem(index) {
      this.meetinfo.medicalrecord.consulting.splice(index, 1);
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.consulting, 'medicalrecord.consulting');
    },
    deletewestrecipeItem(index) {
      this.meetinfo.medicalrecord.westrecipe.splice(index, 1);
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.westrecipe, 'medicalrecord.westrecipe');
    },
    deletezhcnrecipes(index,keyid) {
      //this.meetinfo.medicalrecord.zhcnrecipe[index].productInfos.splice(keyid, 1);
      // 创建一个新的数组，不改变原始数组  
      let newArray = [...this.meetinfo.medicalrecord.zhcnrecipe];  
      // 删除指定索引的子元素  
      newArray[index].productInfos.splice(keyid, 1);   
      this.$set(this.meetinfo, 'medicalrecord.zhcnrecipe', newArray);  
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.zhcnrecipe, 'medicalrecord.zhcnrecipe');
    },
    delzhcnrecipeArray(index) {
      this.meetinfo.medicalrecord.zhcnrecipe.splice(index, 1);
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.zhcnrecipe, 'medicalrecord.zhcnrecipe');
    },
    showdeletebtn(){
      this.delbtn = true
    },
    hidedeletebtn(){
      this.delbtn = false
    },
    handleRowClickconsulting(row) {
      // 在这里处理行点击事件，将点击的行数据添加到 meetinfo.medicalrecord.consulting 数组中
      row.goodid = row._id
      row.defaultfrequency = 1
      row.defaultdays = 1
      row.defaultcount = 1
      row.ischarge = false
      row.istruepay = 0
      row.selectedPackageUnit = row.packageUnit
      const { _id, ...newConsulting } = row; // 创建新的对象并复制行数据
      console.log(this.meetinfo.medicalrecord.consulting)
      this.meetinfo.medicalrecord.consulting.push(newConsulting);
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.consulting, 'medicalrecord.consulting');
    },
    handleRowClickwestrecipe(row) {
      // 在这里处理行点击事件，将点击的行数据添加到 meetinfo.medicalrecord.westrecipe 数组中
      row.goodid = row._id
      row.defaultdays = null
      row.defaultcount = null
      row.ischarge = false
      row.istruepay = 0
      row.selectedPackageUnit = row.packageUnit
      row.selecteddosageUnit = row.medicineDosageUnit
      row.multiplier = 1
      row.usage = row.usage?row.usage:"口服"
      row.freq = "每天1次"
      const { _id, ...newConsulting } = row;  // 创建新的对象并复制行数据
      console.log(this.meetinfo.medicalrecord.westrecipe)
      this.meetinfo.medicalrecord.westrecipe.push(newConsulting);
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.westrecipe, 'medicalrecord.westrecipe');
    },
    handleRowClickzhcnrecipe(row) {
      //在这里处理行点击事件，将点击的行数据添加到 meetinfo.medicalrecord.westrecipe 数组中
      row.istruepay = 0
      row.ischarge = false
      row.goodid = row._id
      const { _id, ...newConsulting } = row;
      console.log(newConsulting)
      //this.meetinfo.medicalrecord.zhcnrecipe[this.zhcnrecipearrayindex].productInfos.push(newConsulting);
      this.$set(this.meetinfo.medicalrecord.zhcnrecipe[this.zhcnrecipearrayindex].productInfos, this.meetinfo.medicalrecord.zhcnrecipe[this.zhcnrecipearrayindex].productInfos.length, newConsulting); 
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.zhcnrecipe, 'medicalrecord.zhcnrecipe');
      this.searchZynameitem = ""
      this.$nextTick(() => {
        console.log(this.meetinfo.medicalrecord.zhcnrecipe[this.zhcnrecipearrayindex].productInfos.length)
        const refName = this.grammagefocusRefs(this.zhcnrecipearrayindex,this.meetinfo.medicalrecord.zhcnrecipe[this.zhcnrecipearrayindex].productInfos.length - 1);
        const inputElement = document.getElementById(refName);
        console.log(inputElement)
        if (inputElement) {
          inputElement.focus();
        }
      });
    },
    clickupupdatazhcnrecipe(row, column, event) {
      row.istruepay = 0
      row.ischarge = false
      row.goodid = row._id
      const { _id, ...newproductInfos } = row;
      this.meetinfo.medicalrecord.zhcnrecipe[this.zhcnrecipearrayindex].productInfos.splice(this.zhcnrecipearraykeyid, 1, newproductInfos);
      this.$nextTick(() => {
        const refName = this.grammagefocusRefs(this.zhcnrecipearrayindex,this.zhcnrecipearraykeyid);
        const inputElement = document.getElementById(refName);
        if (inputElement) {
          inputElement.focus();
        }
      });
      this.serachdisplayName = ""
    },
    agreeChange(id,value,key) {
      store.commit('notice/updateMeetperson', { id, value, key });
    },
    visithistorydetail(data){
      this.innerVisible = true
      this.historydetail = data
      console.log(data)
    },
    cancelsaveprescription(){
      this.isdisabled = true
    },
    getChartDataBig(name){
      const data = {
        title: name,
      };
      this.$api.templates.searchzhprescription(data).then((res) => {
        this.detaildata = res.data.data.zhcnrecipe
        this.savezhcndrugs = res.data.data.zhcnrecipe
      });
    },
    closedialog() {
      this.dialogTableVisible = false;
      this.zhcndrugdialog = false;
      this.consultingdialog = false;
    },
    handleClosewest(tag) {
      this.meetinfo.medicalrecord.westrecipe.splice(
        this.meetinfo.medicalrecord.westrecipe.indexOf(tag),
        1
      );
    },
    handleClosezhcn(tag) {
      this.meetinfo.medicalrecord.zhcnrecipe.splice(
        this.meetinfo.medicalrecord.zhcnrecipe.indexOf(tag),
        1
      );
    },
    handleCloseconsulting(tag) {
      this.meetinfo.medicalrecord.consulting.splice(
        this.meetinfo.medicalrecord.consulting.indexOf(tag),
        1
      );
    },
    deleteItem(index, list) {
      list.splice(index, 1);
    },
    deletezhcndrugs(index, list) {
      list.splice(index, 1);
    },
    deleteconsulting(index, list) {
      list.splice(index, 1);
    },
    openwestdrug() {
      this.dialogTableVisible = true;
    },
    openconsulting() {
      this.consultingdialog = true;
    },
    openzhcndrug() {
      this.zhcndrugdialog = true;
      this.savezhcndrugs = this.meetinfo.medicalrecord.zhcnrecipe
    },
    openshortcutzhcndrug() {
      this.shortcutzhcndrugdialog = true;
      this.detaildata = this.meetinfo.medicalrecord.zhcnrecipe
    },
    closezhcndrug() {
      this.shortcutzhcndrugdialog = false;
      this.detaildata = []
      console.log("123")
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.searchdrug();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.searchdrug();
    },
    zhcnhandlePageChange(page) {
      this.zhcncurrentPage = page;
      this.searchzhcndrug();
    },
    zhcnhandlePageSizeChange(pageSize) {
      this.zhcnpageSize = pageSize;
      this.searchzhcndrug();
    },
    consulthandlePageChange(page) {
      this.consultcurrentPage = page;
      this.searchconsulting();
    },
    consulthandlePageSizeChange(pageSize) {
      this.consultpageSize = pageSize;
      this.searchconsulting();
    },
    chooceclass(index, data) {
      this.orderinfo = data
      this.chooceactive = data._id;
      this.meetinfo._id = data._id;
      this.meetinfo.openid = data.openid;
      this.meetinfo.meetperson = data.meetperson;
      this.meetinfo.mobile = data.mobile;
      this.meetinfo.idcard = data.idcard;
      this.meetinfo.age = data.age;
      this.meetinfo.gender = data.gender;
      this.meetinfo.doctor = data.doctor;
      this.meetinfo.status = data.status;
      this.meetinfo.signin = data.signin;
      this.meetinfo.statusvisit = data.statusvisit;
      this.meetinfo.blswitch = data.blswitch;
      this.meetinfo.date = Moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss");
      this.meetinfo.medicalrecord.visituserid = data.meetpersonid;
      this.getvisithistory(this.meetinfo.medicalrecord.visituserid)
      this.meetinfo.medicalrecord.visittime = Moment(data.updatedAt).format("YYYY-MM-DD HH:mm:ss");
      this.meetinfo.medicalrecord.doctor = data.doctor;
      this.meetinfo.medicalrecord.visitusername = data.meetperson;
      this.meetinfo.medicalrecord.patientdescribe = data.medicalrecord ? data.medicalrecord.patientdescribe : "";
      this.meetinfo.medicalrecord.historyofpresent = data.medicalrecord ? data.medicalrecord.historyofpresent : "";
      this.meetinfo.medicalrecord.pasthistory = data.medicalrecord ? data.medicalrecord.pasthistory : "";
      this.meetinfo.medicalrecord.allergyhistory = data.medicalrecord ? data.medicalrecord.allergyhistory : "";
      this.meetinfo.medicalrecord.auscultationandpalpation = data.medicalrecord ? data.medicalrecord.auscultationandpalpation : "";
      this.meetinfo.medicalrecord.diagnosis = data.medicalrecord ? data.medicalrecord.diagnosis : "";
      this.meetinfo.medicalrecord.dialectical = data.medicalrecord ? data.medicalrecord.dialectical : "";
      this.meetinfo.medicalrecord.exhort = data.medicalrecord ? data.medicalrecord.exhort : "";
      this.meetinfo.medicalrecord.inspect = data.medicalrecord ? data.medicalrecord.inspect : "";
      this.meetinfo.medicalrecord.history = data.medicalrecord ? data.medicalrecord.history : "";
      this.meetinfo.medicalrecord.symptom = data.medicalrecord ? data.medicalrecord.symptom : "";
      this.meetinfo.medicalrecord.westrecipe = data.medicalrecord && data.medicalrecord.westrecipe !== undefined ? data.medicalrecord.westrecipe : [];
      this.meetinfo.medicalrecord.zhcnrecipe = data.medicalrecord && data.medicalrecord.zhcnrecipe !== undefined ? data.medicalrecord.zhcnrecipe : [];
      this.meetinfo.medicalrecord.consulting = data.medicalrecord && data.medicalrecord.consulting !== undefined ? data.medicalrecord.consulting : [];
      this.meetinfo.medicalrecord.consultingtotalCost = data.medicalrecord && data.medicalrecord.consultingtotalCost !== undefined ? data.medicalrecord.consultingtotalCost : 0;
      this.meetinfo.medicalrecord.prescribetitle = data.medicalrecord ? data.medicalrecord.prescribetitle : "";
      this.meetinfo.medicalrecord.prescribenum = data.medicalrecord ? data.medicalrecord.prescribenum : 0;
      this.meetinfo.medicalrecord.total = data.medicalrecord ? data.medicalrecord.total : 0;
    },
    change(date) {
      this.date = Moment(date).format("YYYY-MM-DD");
      this.loadItems();
    },
    loadItems() {
      const data = {
        date: this.date,
      };
      store.dispatch("notice/fetchVisit", data);
    },
    cancellvisit(id) {
      this.$api.visit.cancellvisit(id).then((res) => {
        if (res.data.code === 200) {
          this.chooceclass(this.chooceactive, res.data.data)
          this.agreeChange(this.meetinfo._id, true, 'blswitch');
          this.agreeChange(this.meetinfo._id, 'cancelled', 'statusvisit');
          this.$message({
            message: "该患者已过号",
            type: "warning",
          });
        }else{
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    searchwestdrug(type) {
      this.selectiondrugs = [];
      this.drugnummax = 0;
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        searchdrug: this.searchdrug,
        type: type,
      };
      this.$api.inventory.westdrugpagination(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.tableData = res.data.data;
      });
    },
    searchzhcndrug() {
      this.selectionzhcndrug = [];
      this.drugnummax = 0;
      const data = {
        currentPage: this.zhcncurrentPage,
        pageSize: this.zhcnpageSize,
        searchdrug: this.searchdrug,
      };
      if (!data.searchdrug) {
        return this.$message({
          message: "请输入需要添加的药物名称进行查询",
          type: "danger",
        });
      }
      this.$api.inventory.zhcndrugpagination(data).then((res) => {
        this.zhcncurrentPage = res.data.pagination.currentPage;
        this.zhcnpageSize = res.data.pagination.pageSize;
        this.zhcntotal = res.data.pagination.total;
        this.zhcndrugData = res.data.data;
      });
    },
    searchconsulting() {
      this.selectionconsulting = [];
      this.drugnummax = 0;
      const data = {
        currentPage: this.consultcurrentPage,
        pageSize: this.consultpageSize,
        searchconsulting: this.searchdrug,
      };
      if (!data.searchconsulting) {
        return this.$message({
          message: "请输入需要添加的项目名称进行查询",
          type: "danger",
        });
      }
      this.$api.inventory.consultingpagination(data).then((res) => {
        this.consultcurrentPage = res.data.pagination.currentPage;
        this.consultpageSize = res.data.pagination.pageSize;
        this.consulttotal = res.data.pagination.total;
        this.consultData = res.data.data;
      });
    },
    //修改病历开关
    editprescription() {
      this.isdisabled = false;
    },
    //保存病例
    saveprescription() {
      if(!this.meetinfo.medicalrecord.patientdescribe){
        return this.$message({
          type: "danger",
          message: "主诉不能为空",
        });
      }
      if(!this.meetinfo.medicalrecord.diagnosis){
        return this.$message({
          type: "danger",
          message: "诊断不能为空",
        });
      }
      let allDefaultconsulting = this.meetinfo.medicalrecord.consulting.every(item => item.defaultcount > 0); 
      let allDefaultwestrecipe = this.meetinfo.medicalrecord.westrecipe.every(item => item.defaultcount > 0); 
      let allDefaultzhcnrecipe = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.dosecount > 0);
      let zhcnrecipeproductInfos = this.meetinfo.medicalrecord.zhcnrecipe.every(item => item.productInfos.every(info => info.grammage > 0));   
      if(!allDefaultconsulting || !allDefaultwestrecipe || !allDefaultzhcnrecipe || !zhcnrecipeproductInfos){
        return this.$message({
          type: "danger",
          message: "总量不能为空",
        });
      }
      this.$confirm("点击完诊将会保存病例以及处方", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "保存",
        cancelButtonText: "关闭",
      })
        .then(() => {
          const data = {
            _id: this.meetinfo._id,
            openid: this.meetinfo.openid,
            gender: this.meetinfo.gender,
            age: this.meetinfo.age,
            medicalrecord: this.meetinfo.medicalrecord,
          };
          this.$api.visit.saveprescription(data).then((res) => {
            if (res.data.code === 200) {
              this.chooceclass(this.chooceactive, res.data.data)
              this.agreeChange(this.meetinfo._id, true, 'blswitch');
              this.agreeChange(this.meetinfo._id, 'finish', 'statusvisit');
              this.$message({
                type: "success",
                message: res.data.msg,
              });
            }else{
              this.$alert(res.data.msg, '发生错误', {
                confirmButtonText: '确定',
              });
            }
          });
        })
        .catch((action) => {
          this.isdisabled = true;
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃保存" : "关闭窗口",
          });
        });
    },
    GetAge(identityCard) {
      var len = (identityCard + "").length;
      if (len == 0) {
        return 0;
      } else {
        if (len != 15 && len != 18) {
          //身份证号码只能为15位或18位其它不合法
          return 0;
        }
      }
      var strBirthday = "";
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday =
          identityCard.substr(6, 4) +
          "/" +
          identityCard.substr(10, 2) +
          "/" +
          identityCard.substr(12, 2);
      }
      if (len == 15) {
        strBirthday =
          "19" +
          identityCard.substr(6, 2) +
          "/" +
          identityCard.substr(8, 2) +
          "/" +
          identityCard.substr(10, 2);
      }
      //时间字符串里，必须是“/”
      var birthDate = new Date(strBirthday);
      var nowDateTime = new Date();
      var age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    getvisithistory(id){
      this.$api.visit.getvisithistory(id).then((res) => {
        this.visithistorydata = res.data.data
      })
    },
    getidmeetorder(id){
      this.$api.visit.getidmeetorder(id).then((res) => {
        if(res.data.code === 200){
          this.meetinfo = res.data.data
        }
      })
    },
    alltemplates() {
      this.$api.templates.allComprehensive().then(res => {
        const data = res.data.data
        data.forEach(item => {
          const type = item.type;
          if (this.categorizedData[type]) {
            this.categorizedData[type].push(item);
          } else {
            this.categorizedData[type] = [item];
          }
        });
        console.log(this.categorizedData);
      })
    },
    //主诉
    addpatientdescribe(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.patientdescribe === undefined) {
        this.meetinfo.medicalrecord.patientdescribe = '';
      }
      if (this.meetinfo.medicalrecord.patientdescribe === '') {
        this.meetinfo.medicalrecord.patientdescribe = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.patientdescribe, 'medicalrecord.patientdescribe');
      } else {
        this.meetinfo.medicalrecord.patientdescribe += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.patientdescribe, 'medicalrecord.patientdescribe');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover1.updatePopper()
      })
    },
    //现病史
    addhistoryofpresent(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.historyofpresent === undefined) {
        this.meetinfo.medicalrecord.historyofpresent = '';
      }
      if (this.meetinfo.medicalrecord.historyofpresent === '') {
        this.meetinfo.medicalrecord.historyofpresent = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.historyofpresent, 'medicalrecord.historyofpresent');
      } else {
        this.meetinfo.medicalrecord.historyofpresent += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.historyofpresent, 'medicalrecord.historyofpresent');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover2.updatePopper()
      })
    },
    //既往史
    addpasthistory(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.pasthistory === undefined) {
        this.meetinfo.medicalrecord.pasthistory = '';
      }
      if (this.meetinfo.medicalrecord.pasthistory === '') {
        this.meetinfo.medicalrecord.pasthistory = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.pasthistory, 'medicalrecord.pasthistory');
      } else {
        this.meetinfo.medicalrecord.pasthistory += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.pasthistory, 'medicalrecord.pasthistory');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover3.updatePopper()
      })
    },
    //过敏史
    addallergyhistory(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.allergyhistory === undefined) {
        this.meetinfo.medicalrecord.allergyhistory = '';
      }
      if (this.meetinfo.medicalrecord.allergyhistory === '') {
        this.meetinfo.medicalrecord.allergyhistory = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.allergyhistory, 'medicalrecord.allergyhistory');
      } else {
        this.meetinfo.medicalrecord.allergyhistory += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.allergyhistory, 'medicalrecord.allergyhistory');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover4.updatePopper()
      })
    },
    //检查
    addinspect(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.inspect === undefined) {
        this.meetinfo.medicalrecord.inspect = '';
      }
      if (this.meetinfo.medicalrecord.inspect === '') {
        this.meetinfo.medicalrecord.inspect = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.inspect, 'medicalrecord.inspect');
      } else {
        this.meetinfo.medicalrecord.inspect += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.inspect, 'medicalrecord.inspect');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover5.updatePopper()
      })
    },
    //望闻切诊
    addauscultationandpalpation(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.auscultationandpalpation === undefined) {
        this.meetinfo.medicalrecord.auscultationandpalpation = '';
      }
      if (this.meetinfo.medicalrecord.auscultationandpalpation === '') {
        this.meetinfo.medicalrecord.auscultationandpalpation = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.auscultationandpalpation, 'medicalrecord.auscultationandpalpation');
      } else {
        this.meetinfo.medicalrecord.auscultationandpalpation += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.auscultationandpalpation, 'medicalrecord.auscultationandpalpation');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover6.updatePopper()
      })
    },
    //诊断
    adddiagnosis(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.diagnosis === undefined) {
        this.meetinfo.medicalrecord.diagnosis = '';
      }
      if (this.meetinfo.medicalrecord.diagnosis === '') {
        this.meetinfo.medicalrecord.diagnosis = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.diagnosis, 'medicalrecord.diagnosis');
      } else {
        this.meetinfo.medicalrecord.diagnosis += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.diagnosis, 'medicalrecord.diagnosis');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover7.updatePopper()
      })
    },
    //辩证
    adddialectical(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.dialectical === undefined) {
        this.meetinfo.medicalrecord.dialectical = '';
      }
      if (this.meetinfo.medicalrecord.dialectical === '') {
        this.meetinfo.medicalrecord.dialectical = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.dialectical, 'medicalrecord.dialectical');
      } else {
        this.meetinfo.medicalrecord.dialectical += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.dialectical, 'medicalrecord.dialectical');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover8.updatePopper()
      })
    },
    //医嘱
    addexhort(value) {
      // 处理按钮点击事件
      // 保持输入框的焦点和输入状态
      //this.$refs.codeInput.focus()
      if (this.meetinfo.medicalrecord.exhort === undefined) {
        this.meetinfo.medicalrecord.exhort = '';
      }
      if (this.meetinfo.medicalrecord.exhort === '') {
        this.meetinfo.medicalrecord.exhort = value;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.exhort, 'medicalrecord.exhort');
      } else {
        this.meetinfo.medicalrecord.exhort += `, ${value}`;
        this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.exhort, 'medicalrecord.exhort');
      }
      this.$nextTick(() => {
        this.$refs.filterPopover9.updatePopper()
      })
    },
    guanbi(name){
      this[name] = false;
      this.$refs[name].blur()
    },
    focusInput (name) {
      this[name] = false;
    },
    handleInputFocus(name) {
      this[name] = true;
    },
    InputzhongxichengyaoFocus(name) {
      this[name] = true;
    },
    getPackageUnits(item) {
      // 根据选中的商品规格动态生成可选的packageUnit数组
      if (item.dismounting === 1) {
        return [item.packageUnit, item.pieceUnit];
      } else{
        return [item.packageUnit];
      }
    },
    selectdosageunit(item) {
      return [item.medicineDosageUnit, item.pieceUnit];
    },
    getPackagePrice(item) {
      // 根据选中的packageUnit值返回不同的价格
      if (item.selectedPackageUnit === item.packageUnit) {
        return item.sellprice;
      }else{
        return item.pieceprice;
      }
    },
    getPackagewestrecipePrice(item) {
      // 根据选中的packageUnit值返回不同的价格
      if (item.selectedPackageUnit === item.packageUnit) {
        return item.sellprice;
      }else if(item.selectedPackageUnit === item.pieceUnit){
        return item.pieceprice;
      }
    },
    handleUsageClick(item,itemusage) {
      console.log(itemusage)
      item.usage = itemusage;
    },
    handlezycfUsageClick(item,itemusage) {
      item.usages = itemusage;
    },
    handlezycfdoseClick(item,itemusage) {
      item.dose = itemusage;
    },
    handlezycffrequencyClick(item,itemusage) {
      item.frequency = itemusage;
    },
    handlezycfdosagetakenClick(item,itemusage) {
      item.dosagetaken = itemusage;
    },
    handleCardButtonClick(cardId,row) {
      // Handle the button click event here
      console.log("Clicked button with ID:", cardId);
      console.log("Clicked button with ID:", row);
    },
    zhcnrecipetotalCost(index, item) {
      let totalPrice = 0.00;
      let total = 0.00;
      const dosecount = this.meetinfo.medicalrecord.zhcnrecipe[index]?.dosecount;
      const processingpreice = this.meetinfo.medicalrecord.zhcnrecipe[index]?.processingpreice;
      const numprocessingpreice = parseFloat(processingpreice); 
      const productInfos = this.meetinfo.medicalrecord.zhcnrecipe[index]?.productInfos;
      if (!productInfos || productInfos.length === 0 || dosecount < 0) {
        return '0.00';
      }
      const filteredProductInfos = productInfos.filter(i => i.grammage > 0);
      filteredProductInfos.forEach(i => {
        if (i.grammage > 0 && numprocessingpreice > 0) {
          total += i.grammage * i.sellprice * dosecount;
          totalPrice = total + numprocessingpreice
        }else if(i.grammage > 0){
          total += i.grammage * i.sellprice * dosecount;
          totalPrice = total
        }else{
          totalPrice = 0.00
        }
      });
      item.totalPrice = totalPrice.toFixed(2);
      return totalPrice.toFixed(2)
    },
    zhcnrecipequantity(index, item) {
      const dosecount = this.meetinfo.medicalrecord.zhcnrecipe[index]?.dosecount;
      const productInfos = this.meetinfo.medicalrecord.zhcnrecipe[index]?.productInfos;
      const filteredProductInfos = productInfos.filter(i => i.grammage > 0);
      return filteredProductInfos.length
    },
    zhcnrecipequantitytotal(index, item) {
      const productInfos = this.meetinfo.medicalrecord.zhcnrecipe[index]?.productInfos;
      const filteredProductInfos = productInfos.filter(i => i.grammage > 0);
      const total = filteredProductInfos.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.grammage), 0);
      const totalPrice = this.meetinfo.medicalrecord.zhcnrecipe.map(item => Number(item.totalPrice)).reduce((sum, price) => sum + price, 0); 
      this.meetinfo.medicalrecord.zhcnrecipetotalPrice = totalPrice.toFixed(2)
      this.agreeChange(this.meetinfo._id, totalPrice, 'medicalrecord.zhcnrecipetotalPrice');
      return total;
    },
    processingpreiceInput(item,value) {
      // 使用正则表达式验证输入的值
      const regex = /^(?!0\d)(\d+(\.\d{0,2})?)?$/;
      if (!regex.test(item.processingpreice)) {
        // 如果输入的值不符合要求，则将其设为空字符串
        item.processingpreice =  null;
      }
    },
    restrictToPositiveInteger(item,keyid,value) {
      // 使用正则表达式验证输入的值
      const regex = /^(?!0\d)(\d+(\.\d{0,2})?)?$/;
      if (!regex.test(item.productInfos[keyid].grammage)) {
        // 如果输入的值不符合要求，则将其设为空字符串
        item.productInfos[keyid].grammage = null;
      }
    },
    //接诊
    confirmvisit(data){
      this.$api.visit.confirmvisit(data).then((res) => {
        if(res.data.code === 200){
          this.meetinfo.statusvisit = res.data.data.statusvisit
          this.meetinfo.blswitch = false
          this.agreeChange(this.meetinfo._id, res.data.data.statusvisit, 'statusvisit');
          this.agreeChange(this.meetinfo._id, false, 'blswitch');
        }
        this.$message({
            message:res.data.msg,
            type: res.data.code === 200 ? "success" : "warning",
        });
      })
    },
    //防抖
    searchtreatmentandmedicineInput(){
      this.debounce(this.searchtreatmentandmedicine(), 500);
    },
    searchtreatmentandmedicineFocus(){
      this.showPopupzhengliaoxiangmu = true
      this.searchtreatmentandmedicine()
    },
    searchtreatmentandmedicineblur() {
      this.searchchconsulting = ''
      this.showPopupzhengliaoxiangmu = false
      // this.searchtreatmentandmedicine()
    },
    searchtreatmentandmedicine(){
      const data = {
        search:this.searchchconsulting
      }
      this.$api.goods.searchtreatmentandmedicine(data).then((res) => {
        if(res.data.code === 200){
          this.gridDataceshi1 = res.data.data
          console.log("变化",this.gridDataceshi1)
        }
      })
    },
    searchwesternmedicineInput(){
      this.debounce(this.searchvisitwesternmedicine(), 500);
    },
    searchwesternmedicineFocus(){
      this.showPopupzhongxichengyao = true
      this.searchvisitwesternmedicine()
    },
    searchwesternmedicineblur() {
      this.searchwesternmedicine = ''
      this.showPopupzhongxichengyao = false
    },
    searchvisitwesternmedicine(){
      const data = {
        search:this.searchwesternmedicine
      }
      this.$api.goods.searchvisitwesternmedicine(data).then((res) => {
        if(res.data.code === 200){
          this.gridDataceshi2 = res.data.data
        }
      })
    },
    searchchinesemedicineInput(){
      this.debounce(this.searchvisitchinesemedicine(), 500);
    },
    searchvisitchinesemedicine(){
      const data = {
        search:this.searchZynameitem
      }
      this.$api.goods.searchvisitchinesemedicine(data).then((res) => {
        if(res.data.code === 200){
          this.gridDataceshi3 = res.data.data
        }
      })
    },
    searchdisplayNameInput(){
      this.debounce(this.searchvisitserachdisplayNamemedicine(), 500);
    },
    searchvisitserachdisplayNamemedicine(){
      const data = {
        search:this.serachdisplayName
      }
      this.$api.goods.searchvisitchinesemedicine(data).then((res) => {
        if(res.data.code === 200){
          this.gridDataceshi3 = res.data.data
        }
      })
    },
  },
  created() {
    this.loadItems()
    this.alltemplates()
    this.searchvisitwesternmedicine()
    this.getpermissionscategories()
    this.getchangedoctorlist()
  },
  computed: {
    defaultGender() {
      console.log("11111", this.meetinfo.gender);  
      if(!this.meetinfo.gender){
        return ""; 
      }else if(this.meetinfo.gender === 0){
        return "女"  
      }else{
        return "男"
      }  
    },
    totalCost() {
      let totalPrice = 0;
      this.meetinfo.medicalrecord.consulting.forEach(item => {
        totalPrice += (item.defaultcount) * (this.getPackagePrice(item));
      });
      this.meetinfo.medicalrecord.consultingtotalCost = totalPrice
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.consultingtotalCost, 'medicalrecord.consultingtotalCost');
      return totalPrice.toFixed(2);
    },
    totalPrices() {
      const undataconsulting = this.meetinfo.medicalrecord.consulting.map(item => ({
      ...item,
      Totalsellingprice: (item.defaultcount * this.getPackagePrice(item)).toFixed(2)
      }));
      this.meetinfo.medicalrecord.consulting = undataconsulting
      this.agreeChange(this.meetinfo._id, undataconsulting, 'medicalrecord.consulting');
      return this.meetinfo.medicalrecord.consulting.map(item => (item.defaultcount) * (this.getPackagePrice(item))).map(price => price.toFixed(2));
    },
    westrecipetotalCost() {
      let totalPrice = 0;
      this.meetinfo.medicalrecord.westrecipe.forEach(item => {
        totalPrice += (item.defaultcount) * (this.getPackagePrice(item));
      });
      this.meetinfo.medicalrecord.westrecipetotalCost = totalPrice
      this.agreeChange(this.meetinfo._id, this.meetinfo.medicalrecord.westrecipetotalCost, 'medicalrecord.westrecipetotalCost');
      return totalPrice.toFixed(2);
    },
    westrecipetotalPrices() {
      const undatawestrecipe = this.meetinfo.medicalrecord.westrecipe.map(item => ({
      ...item,
      Totalsellingprice: (item.defaultcount * this.getPackagewestrecipePrice(item)).toFixed(2)
      }));
      this.meetinfo.medicalrecord.westrecipe = undatawestrecipe
      this.agreeChange(this.meetinfo._id, undatawestrecipe, 'medicalrecord.westrecipe');
      return this.meetinfo.medicalrecord.westrecipe.map(item => (item.defaultcount) * (this.getPackagewestrecipePrice(item))).map(price => price.toFixed(2));
    },
    zhcnrecipetotalPrice() {
      const totalPrice = this.meetinfo.medicalrecord.zhcnrecipe.map(item => Number(item.totalPrice)).reduce((sum, price) => sum + price, 0); 
      this.meetinfo.medicalrecord.zhcnrecipetotalPrice = totalPrice.toFixed(2)
      this.agreeChange(this.meetinfo._id, totalPrice, 'medicalrecord.zhcnrecipetotalPrice');
    },
    cardButtonHandlers() {
      return this.meetinfo.medicalrecord.zhcnrecipe.map((item, index) => {
        return {
          id: 'button-' + index,
          handler: () => {
            // 您可以在这里编写处理按钮点击事件的逻辑
            console.log("点击了 id 为 " + this.id + " 的按钮");
          }
        };
      });
    },
  },
  mounted() {
    this.listclinic()
    this.getdoctor()
  },
  beforeDestroy() {
  },
};
</script>
<style>
.yizhen{
  color: #5189dd;
}
.jiuzhenzhong{
  color:#1ff566
}
.weizhen{
  color: #9d00ff;
}
.digolPopover {
  height: 280px;
  overflow: auto;
}
.createorder{
  margin-top: 5px;
  justify-content: flex-end;
  display: flex;
}
.newmeetvisitselect .el-input .el-input__inner {
  width: 140px!important;
}
.align-center{
  align-items: center;
}
.justify-space-between {
  justify-content: space-between;
}
.newmeetvisittop .el-input--prefix {
  width: 140px;
}
.newmeetvisittop{
  display: flex;
  align-items: center;
}
.jrjiuzhen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.changedoctorbox .el-dialog__footer {
  text-align: end;
}
.changedoctorbox .el-select .el-input__inner {
  width:100%!important;
}
.el-input__prefix {
  left: 0px;
}
.el-input .el-input__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
}
.w280{
  width: 280px;
}
.chaochuyc{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.aligncenter{
  align-items: center;
}
.lineheight50{
  line-height:50px;
}
.Zynameitemindex{
  display:none;
}
.searchmedicinedatatotal{
  text-align: center;
  padding: 10px 10px;
  border-bottom: 1px solid #dadbe0;
}
.activemedicineTemplate:hover {  
  cursor: pointer;
  background-color: #bebebe;
}
.activeitem {  
  color: white;
  background-color: #5189dd!important; 
}  
.el-popover {
  padding: 10 !important;
}
.el-dialog__wrapper .eldialog{
  margin-top: 5vh;
}
.eldialog .el-dialog__body {
  padding: 0!important;
}
.eldialog .el-dialog__header {
  padding: 0!important;
}
.eldialogbox{
  display:flex;
  flex-direction:row;
  height:700px;
  flex-grow:1
}
.eldialogboxleft{
  width:35%;
  background-color:#f5f7fb;
  display: flex;
  flex-direction: column;
  height:100%;
  border-right:1px solid #dadbe0;
}
.eldialogboxright{
  width:65%;
  display: flex;
  flex-direction: column;
}
.left-header{
  padding:10px;
  border-bottom: 1px solid #dadbe0;
}
.left-header .el-input__inner{
  height:35px
}
.left-tabs{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.left-tabs .el-tabs__nav{
  margin-left: 10px;
  margin-right: 10px;
}
.left-tabs-centent{
  display:flex;
  flex-direction:row;
  flex-grow:1;
  height:600px;
}
.left-tabs-box{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.left-tabs-box .el-tabs__header{
  margin:0;
}
.left-tabs-box .el-tabs__content{
  display: flex;
  flex-grow: 1;
  height:100%;
}
.left-tabs-box .el-tabs__content .el-tab-pane{
  display: flex;
  flex-grow: 1;
}
.tabs-centent-left{
  width:50%;
  border-right:1px solid #dadbe0;
  display: flex;
  flex-direction: column;
}
.boxli{
  flex-direction: row;
  display: flex;
  padding: 5px 5px;
  width: 100%;
}
.boxliicon{
  color:#72acf3;
  font-size:20px
}
.tabs-centent-right{
  width:50%;
  display: flex;
  flex-direction: column;
}
.right-header{
  padding:10px;
  text-align:center;
  border-bottom: 1px solid #dadbe0;
}
.righttitle{
  height:35px;
  font-size:24px;
  color:#000000
}
.eldialog .el-dialog__headerbtn{
  top: 10px!important;
}
.eldialog .el-dialog__headerbtn .el-dialog__close{
  font-size: 32px!important;
}
.right-useinfo{
  border: 1px solid #dadbe0;
  margin: 20px;
  background-color: #f5f7fb;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
}
.useinfotop{
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid #dadbe0;
}
.useinfoclass{
  width: 100px;
  padding: 10px;
  border-right: 1px solid #dadbe0;
}
.useinfoclasscentent{
  padding: 10px;
  border-right: 1px solid #dadbe0;
}
.lastuseinfoclasscentent{
  padding: 10px;
}
.oneuseinfoclasscentent{
  width: 100%;
  padding: 10px;
}
.lastuseinfotop{
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  text-align: center;
}
.right-creater{
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.right-useinfofoot{
  border: 1px solid #dadbe0;
  margin: 0px 20px;
  background-color: #f5f7fb;
  border-radius: 2px;
  flex-grow:1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.useinfofoottitle{
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #dadbe0;
  display: flex;
  flex-direction: column;
}
.zycfmedicineCadn {
  display: flex;
  flex-wrap: wrap;
}

.zycfmedicineCadn > div {
  flex-basis: 25%;
  height:60px;
  border-right: 1px solid #dadbe0;
  border-bottom: 1px solid #dadbe0;
}

.zycfmedicineCadn > div:nth-child(4n) {
  border-right: none;
}
.zycftop{
  display:flex;
  flex-direction:column;
  border: 1px solid #dadbe0;
  margin:20px;
  background-color: #f5f7fb;
}
.zycffoot{
  display:flex;
  flex-direction:column;
}
.lasefootitem{
  width: 100%;
  border-top: 1px solid #dadbe0;
  margin-top: -1px;
  padding: 10px;
  display:flex;
  flex-direction:row;
}
.totaldescription{
  display:flex;
  flex-direction:row;
}
.totaldescription > div{
  margin-right:10px;
}
.zycfmedicineCadnitem{
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}
.eldialogboxright .dialog-footer{
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.historybox .el-tabs__content{
  height:700px;
  padding:0!important;
  overflow: auto;
}
.historybox .el-collapse-item__header.is-active {
  border-bottom-color: #dadbe0;
}
.historybox .el-collapse-item__header{
  height:44px;
}
.historybox .collapsetitle{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding: 0 10px;
}
.flex_1{
  flex:1;
}
.collapsehistorydetail{
  position: relative;
  border-bottom: 1px dashed #e6eaee;
}
.historybox .el-collapse-item__content{
  padding-bottom:0!important;
  background-color: #fbfbfb;
}
.jiachu{
  color: #000;
  font-weight: 700;
}
.binglibtn{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.historyboxrow {
  display:flex;
  flex-direction:row;
}
.historyboxcolumn {
  display:flex;
  flex-direction:column;
  color: #7a8794;
}
.flexgrow_1{
  flex-grow:1;
}
.flex_wrap{
  flex-wrap: wrap;
}
.padbottom10{
  padding-bottom:10px;
}
.searchpatient .el-input__inner{
  background-color: #FFF;
  background-image: none;
  border-radius: 0!important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #DCDFE6;
  padding: 0px 30px;
}
.searchpatienttotal{
  height: 32px;
  border-bottom: 1px solid #DCDFE6;
  color: gray;
  text-align: center;
  font-size: 12px;
  line-height: 32px;
}
.searchpatientitem{
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mr20{
  margin-right: 20px;
}
.searchpatientitem:hover{
  background-color: #DCDFE6;
}
</style>
<style lang="scss" scoped>
.inputnotes .input-element{
  width:100px;
  text-align: left!important;
}
.mrl10{
  margin-left: 10px;
  margin-right: 10px;
}
.ziti table tr th{
  font-family: cursive !important;
  font-weight: 700!important;
  color: #000000!important;
}
.historybtn{
  position: absolute;
  right: 30px;
}
.no-multiple {
  // 隐藏多选
  ::v-deep .el-table__header-wrapper .el-checkbox {
    display: none;
  }
}
thead .el-table-column--selection .cell .el-checkbox{
    display: none;
}
.el-message-box__btns button:focus {
  outline: none;
}
.no-border {
  border: none;
  color: black;
}
.el-tag.el-tag {
  margin: 3px;
}
.uf-text {
  line-height: 20px;
  font-size: 18px;
  letter-spacing: 1px;
}
.uf-f1 {
  flex: 1;
}
.uf {
  display: flex;
  border-bottom: 1px solid #eaeaea;
  flex-direction: row;
  align-items: center;
}
.recipetitle {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}
.recipebtnbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
}
button:focus {
  outline: none;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100px;
}
.home {
  overflow-y: auto;
  height: calc(100vh - 36px);
  background-color: #f6f9f9;
  padding-bottom: 10px;
  padding-right: 10px;
}
.sketch_content {
  // 必须有高度 overflow 为自动
  overflow: auto;

  // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
  &::-webkit-scrollbar {
    width: 3px;
  }

  // 滚动条里面默认的小方块,自定义样式
  &::-webkit-scrollbar-thumb {
    background: #8798af;
    border-radius: 2px;
  }

  // 滚动条里面的轨道
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
.meetlist {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
}
.meetlist:hover{
  background-color: #DCDFE6;
}
.meettitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ml10 {
  margin-left: 10px;
}
.chooceactive {
  background-color: #409eff!important;
  color: #ffffff;
}
.input-container {
  position: relative;
}
.w_100{
  width: 100px;
}
.w_60{
  width: 60px;
}
.w_120{
  width: 120px;
}
.w_80{
  width: 80px;
}
.inputcontent {
  padding: 0 !important;
}

.shortcuttitle h5 {
  font-size: 14px;
  font-weight: 700;
  margin-top: 12px;
  padding-left: 8px;
}
.shortcut{
  border-radius: 4px;
  color: #7a8794;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-bottom: 5px;
  padding: 5px 8px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.shortcut:hover {
  background-color: #c6e2ff;
  color: #0090ff;
}
.el-textarea__inner {
  line-height: inherit;
  vertical-align: middle;
  display: flex;
  padding: 4px !important;
  background-color: #FFF;
  background-image: none;
  border-radius: 0 !important;
  border: none !important;
}
.el-textarea.is-disabled .el-textarea__inner {
  background-color: #F5F7FA;
  border-color: #E4E7ED;
  cursor: not-allowed;
}
.el-textarea {
  position: relative;
  vertical-align: middle;
  font-size: 14px;
}
.minheight42 {
  min-height: 42px;
}
.h3title{
  height: 32px;
  line-height: 32px;
  font-weight: 700;
  font-size: 14px;
  color: #000;
}
.userinfobox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.input-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}
.zhengliaoxiangmu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.zlxmhj {
  border-right: 1px solid #dcdfe6;
}
.zhengliaoxiangmu ::v-deep .el-input__inner{
  border: none;
  border-radius: 0 !important;
}
.input-row ::v-deep .el-input__inner {
  border: none!important;
  outline: none!important;
  border-radius: 0 !important;
}
.mr10{
  margin-right: 10px;
} 
.el-input__inner{
  border-radius: 0 !important;
}
.el-select ::v-deep .el-input__inner{
  width: 80px;
}
.el-table ::v-deep .el-table__cell {
  padding: 5px 0;
}
.el-input-group__prepend{
  background-color: #F5F7FA;
  border: 1px solid #dcdfe6;
  border-radius: 0 !important;
  padding: 0 20px;
}
.binglititle{
  color: #626d77;
  width: 90px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-right: 1px solid #e6eaee;
  box-sizing: border-box; 
}
.binglititle > * {
  flex-grow: 1;
}
.binglibox{
  background-color: #fffdec;
  border-top: 1px solid #e0e2eb;
}
.bingliitem{
  display: flex;
  flex-direction: row;
}
.bingliitem ::v-deep input {
  border: none;
  outline: none;
  border-radius: 0 !important;
}
.bingliitem ::v-deep textarea {
  border: none;
  outline: none;
  border-radius: 0 !important;
  box-shadow: none;
}
.bingliitem + .bingliitem {
  border-top: 1px solid #e0e2eb; /* 替换成你想要的边框样式 */
}
.my-div ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 3px; /* 设置滚动条宽度 */
}

.my-div ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background: #8798af;
  border-radius: 2px;
}

.my-div ::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 设置轨道背景颜色 */
}
.borderbottom{
  border-bottom:1px solid #e0e2eb;
}
.userinfoboxwrapper ul {
  margin: 0;
  padding: 0;
}
.goods-item-list li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-bottom: 1px dashed #e6eaee;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 0;
  position: relative;
}
.goods-item-list .index {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  align-items: center;
  border-right: 1px dashed #e6eaee;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
  justify-content: center;
  width: 30px;
}
.goods-item-list .name {
  -webkit-box-flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  padding-right: 4px;
  width: 0;
}
.goods-item-list .name .name-describe {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-left: 8px;
  width: 100%;
}
.goods-item-list .name .ellipsis {
  cursor: pointer;
  font-weight: 500;
}
.ellipsis {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.medical-fee-grade-td-wrapper .pay-type-text {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 100%;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
}
.goods-item-list .spec {
  color: #7a8794;
  font-size: 12px;
  margin-left: 4px;
  min-width: 20px;
}
.goods-item-list .name .name-describe>div.tag-wrap {
  min-width: 50px;
}
.goods-item-list .goods-type {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  align-items: center;
  border-left: 1px dashed #e6eaee;
  color: #7a8794;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0 6px;
}
.goods-item-list .medical-fee-grade-td-wrapper {
  width: 40px;
}
.medical-fee-grade-td-wrapper {
  border: none;
  height: 100%;
  position: relative;
  width: 100%;
}
.goods-item-list .dosage, .goods-item-list .unit-count {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-left: 1px dashed #e6eaee;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 98px;
}
.bwhl-form-item {
  margin: 0;
}
.bwhl-form-item {
  display: inline-block;
  font-size: 0;
}
.bwhl-form-item .bwhl-form-item-content {
    font-size: 0;
    line-height: 1;
    position: relative;
}
.bwhl-input-wrapper {
    display: inline-block;
    font-size: 14px;
    outline: none;
    position: relative;
}
.goods-item-list .days {
    border-left: 1px dashed #e6eaee;
    height: 100%;
    width: 59px;
}
.bwhl-input__inner {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border: 1px solid #e0e2eb;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #000;
    display: inline-block;
    font-size: 14px;
    height: 32px;
    line-height: 1;
    outline: none;
    padding: 3px 8px;
    width: 100%;
}
.bwhl-input-wrapper.count-center .bwhl-input__inner, .input-select .bwhl-input__inner, .no-border-input .bwhl-input__inner {
    background-color: #fff;
    border-color: transparent;
    border-radius: 0;
    height: 39px;
    padding: 3px 4px;
}
.goods-item-list .dosage span.unit, .goods-item-list .unit-count span.unit {
    display: inline-block;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    width: 48px;
}
.bwhl-form-item .bwhl-form-item-content button, .bwhl-form-item .bwhl-form-item-content p, .bwhl-form-item .bwhl-form-item-content span {
    font-size: 14px;
}
.goods-item-list .delete-item, .goods-item-list .input-append-unit {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  position: absolute;
  top: 0;
}
.goods-item-list .input-append-unit {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    right: 0;
    width: 24px;
    z-index: 2;
}
.count-center .bwhl-input__inner {
    text-align: center!important;
}
.bwhl-input-wrapper.is-hover .bwhl-input__inner:not([disabled]):not(.is-disabled) {
    border-color: #459eff!important;
    z-index: 2!important;
}
.goods-item-list .unit-count {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-left: 1px dashed #e6eaee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 98px;
}
.goods-item-list .total {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-align-items: center;
    align-items: center;
    border-left: 1px dashed #e6eaee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    max-width: 110px;
    min-width: 84px;
    padding-right: 10px;
    position: relative;
    width: 110px;
}
.goods-item-list .item-remark {
    border-left: 1px dashed #e6eaee;
    max-width: 59px;
    min-width: 59px;
    position: relative;
    width: 59px;
}
.goods-item-list .delete-item {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    right: -14px;
    width: 28px;
}
.goods-item-list .unit-count-dosage {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-left: 1px dashed #e6eaee;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
select {
  outline: none;
  appearance: none; /* 清除默认的外观样式 */
  border: none; /* 去除边框 */
  background-color: transparent; /* 设置背景色为透明，以便隐藏默认的下拉箭头 */
  width: 40px;
  height: 40px;
  font-size: medium;
}
.goods-item-list .unit-count-dosage .ishover:hover {
  color: #ffffff;
  background-color: #459eff!important;
}
select::after {
  border: none; /* 去除边框 */
  content: '\25BC'; /* 使用 Unicode 字符作为箭头图标 */
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  right: 0.5rem; /* 根据需要调整箭头位置 */
  transform: translateY(-50%);
  pointer-events: none; /* 避免箭头遮挡点击事件 */
}
select option {
  height: 2rem; /* Set the height of each option in the dropdown */
  /* Other styles for the options */
}
.underline-input {
  position: relative;
}
.underline-input input:hover {
  border: 1px solid;
  border-color: #459eff!important;
}
.zxcy .table-td {
  height: 40px;
}
.zxcy .usage {
  border-left: 1px dashed #e6eaee;
  min-width: 77px;
  width: 9.8%;
}
.zxcy .table-td .bwhl-input__inner {
    border-color: transparent;
    border-radius: 0;
    height: 40px;
}
.selectcontainer {
  display: flex;
  flex-wrap: wrap; /* 设置自动换行 */
  width: 400px; /* 设置父div的固定宽度 */
  border: 1px solid gray;
  border-bottom: none;
}

.column {
  flex: 0 0 25%;
  border-bottom: 1px solid gray; /* 下边框，灰色线条 */
  border-right: 1px solid gray; /* 右边框，灰色线条 */
  text-align: center;
  box-sizing: border-box;
  padding: 10px; /* 设置底部间距 */
}

.selectcontainer .column:hover {
  background-color: #0090ff;
  color: #ffffff;
}

.column:nth-child(4n) {
  border-right: none; /* 每第四个div没有右边框 */
}
.mt10 {
  margin-top: 10px
}
.goods-item-list .name .shortage-tips {
    margin-left: 4px;
}
.el-dropdown-menu {
  max-height: 300px; /* 设置所需的固定高度 */
  overflow-y: auto; /* 当内容溢出时出现垂直滚动条 */
  overflow-x: hidden;
}
.zycf {
  display: flex;
  flex-wrap: wrap;
}
.zycf .child {
  min-height: 58px;
  border-bottom: 1px dashed #e6eaee;
  border-right: 1px dashed #e6eaee;
  width: 20%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #fff;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 58px;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 0 8px 0 4px;
  position: relative;
  vertical-align: top;
}
.zycf .child:nth-child(5n) {
  border-right: none;
}
.delete-icon-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1000;
}
.name-wrapper {
    -webkit-box-flex: 1;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    width: 0;
}
.name-wrapper .zycf-autocomplete-wrapper {
    max-width: 168px;
}
.child .zycf-autocomplete-wrapper {
    background-color: #fff;
}
.zycf-input-small-wrapper {
    line-height: 1;
}
.zycf-autocomplete-wrapper {
    display: inline-block;
    font-size: 14px;
    position: relative;
    width: 100%;
}
.zycf-autocomplete-wrapper .zycf-input__inner {
    -webkit-font-smoothing: initial;
    font-size: 14px;
}
.child .zycf-input__inner {
    border-radius: 0;
    border-width: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    height: 24px;
    line-height: 1;
}
.child .zycf-input__inner {
    border-radius: 0;
    padding: 3px 8px;
}
.child .count-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 15px;
    width: 51px;
}
.child .zycf-form-item {
    margin: 0;
}
.zycf-input-wrapper {
    display: inline-block;
    font-size: 14px;
    outline: none;
    position: relative;
}
.zycf-autocomplete-wrapper .zycf-input__inner {
    width: 100%;
}.zycf-input__inner {
    font-family: STZhongsong;
    font-size: 15px;
    padding: 0 2px;
}
.child .zycf-input__inner {
    border-radius: 0;
    border-width: 0;
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    height: 24px;
    line-height: 1;
}
.count-wrapper .input-append-unit {
    font-family: STZhongsong;
    font-size: 15px;
    width: 16px;
    display: flex;
    align-items: center;
}
.child .input-append-unit {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    height: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    z-index: 2;
}
.delete-icon:hover{
  color: chocolate;
}
.name-wrapper ::v-deep .el-input__inner{
  border: none;
  border-radius: 0 !important;
  padding: 0;
  height: 100%;
  line-height: normal;
}
.bottombox{
  height: 41px;
  padding: 0 12px 0 9px;
}
.bottomboxborder{
  border-bottom: 1px dashed #e6eaee;
}
.flexrow{
  display: flex;
  flex-direction: row;
}
.flex-item {
  flex: 1;
  margin-right: 10px;
}
.flex-item—1{
  align-items: center;
  display: flex;
}
.align-right {
  margin-right: 0;
  margin-left: auto;
}
.nested-flex {
  display: flex;
  align-items: center;
}
.align-right {
  margin-right: 0;
  margin-left: auto;
}
.zycfdose {
  border-bottom: 1px solid #d1d1d1;
}

.input-element {
  width: 40px;
  border: none;
  outline: none;
  text-align: center;
  line-height: 1;
}
.zycfusage ::v-deep .el-popover {
  padding: 0 !important;
}
.zycfusage-input__inner{
  width: 72px;
  border: none;
  text-align: center;
  margin: 0 5px;
  outline: none;
}
.jend{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.transition-element {
  transition: opacity 0.5s; /* 添加过渡效果 */
}
</style>
